import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../index.css";
import 'react-phone-number-input/style.css';
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import axios from "axios";
import Loader from "../components/Loader";
import globals from "../config/globals/globals";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileImage, faArrowRight} from "@fortawesome/free-solid-svg-icons";
import { _ } from "../config/languages/i18n";
import { Modal } from "react-bootstrap";

const Adds = () => {
    const [loading, setLoading] = useState(false);
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [images, setImages] = useState([]);
    const [deleteModal, setDeleteModal] = useState(null);

    useEffect(() => {
        const fetchImages = async () => {
            setLoading(true)
            try {
                const response = await HTTP.get('/getAdds');
                const sortedImages = response.data.sort((a, b) => a.order - b.order);
                setImages(sortedImages.map(image => ({ ...image, photo: null })));
            } catch (error) {
                console.error('Veri alma hatası:', error);
                toast.error(_("connection_error"));
            } finally {
                setLoading(false);
            }
        };

        fetchImages();
    }, []);

    const fetchImagesGlob = async () => {
        setLoading(true)
        try {
            const response = await HTTP.get('/getAdds');
            const sortedImages = response.data.sort((a, b) => a.order - b.order);
            setImages(sortedImages.map(image => ({ ...image, photo: null })));
        } catch (error) {
            console.error('Veri alma hatası:', error);
            toast.error(_("connection_error"));
        } finally {
            setLoading(false);
        }
    };

    const handleImageChange = (index, event) => {
        const updatedImages = [...images];
        updatedImages[index].photo = event.target.files[0];
        setImages(updatedImages);
    };

    const handleNumberChange = (index, event) => {
        const updatedImages = [...images];
        updatedImages[index].number = event.target.value;
        setImages(updatedImages);
    };

    const handleStatusChange = (index) => {
        const updatedImages = [...images];
        updatedImages[index].status = !updatedImages[index].status;
        setImages(updatedImages);
    };

    const addNewImage = () => {
        const maxOrder = images.length > 0 ? Math.max(...images.map(img => img.order)) : 0;
        setImages([...images, { photo: null, number: '', status: 0, order: maxOrder + 1 }]);
    };

    const removeImage = async (index) => {
        const imageToDelete = images[index];

        if (imageToDelete.id) {
            try {
                setLoading(true);
                await HTTP.delete(`/deleteAdd/${imageToDelete.id}`);
                toast.success(_("successful"));
                setDeleteModal(null)
            } catch (error) {
                console.error('Silme hatası:', error);
                toast.error(_("connection_error"));
                setDeleteModal(null)
                return;
            } finally {
                fetchImagesGlob();
                setLoading(false);
                setDeleteModal(null)
            }
        }

        const updatedImages = [...images];
        updatedImages.splice(index, 1);
        updatedImages.forEach((img, idx) => img.order = idx + 1);
        setImages(updatedImages);
    };

    const moveImage = (index, direction) => {
        const updatedImages = [...images];
        const newIndex = index + direction;

        if (newIndex < 0 || newIndex >= updatedImages.length) return;

        const movedImage = updatedImages[index];
        const targetImage = updatedImages[newIndex];

        updatedImages[index] = targetImage;
        updatedImages[newIndex] = movedImage;

        updatedImages.forEach((img, idx) => {
            img.order = idx + 1;
        });

        setImages(updatedImages);
    };

    const handleSubmit = async () => {
        const formData = new FormData();

        images.forEach((image, index) => {
            formData.append(`images[${index}][photo]`, image.photo ? image.photo : "");
            formData.append(`images[${index}][number]`, image.number);
            formData.append(`images[${index}][status]`, image.status ? 1 : 0);
            formData.append(`images[${index}][order]`, image.order);
            if (image.id) {
                formData.append(`images[${index}][id]`, image.id);
            }
        });

        try {
            setLoading(true);
            await HTTP.post('/addAdds', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
            toast.success(_("successful"));
            const response = await HTTP.get('/getAdds');
            const sortedImages = response.data.sort((a, b) => a.order - b.order);
            setImages(sortedImages.map(image => ({ ...image, photo: null })));
        } catch (error) {
            console.error('Yükleme hatası:', error);
            toast.error(_("connection_error"));
        } finally {
            fetchImagesGlob();
            setLoading(false);
        }
    };

    return (
        <div >
            {loading && <Loader />}
            <Navbar />
            <div className="container mt-5">
            <h2 className="reportText">{_("slider_info")}</h2>
                <div className="card shadow mb-2">
                    <h4 className="text-center mt-2 homeText">{_('visual_editing')}</h4>
                </div>
                {images.map((image, index) => (
                    <div key={index} className="card-back p-1 mb-3 rounded">
                        <div className="card p-2">
                            <div className="d-flex justify-content-between align-items-center">
                                {image.photo_url && (
                                    <img
                                        src={`${globals.imgAdds}${image.photo_name}.png`}
                                        alt={image.number}
                                        className="img-fluid rounded"
                                        style={{ maxWidth: '45%' }}
                                    />
                                )}
                                {(image.photo_url && image.photo) &&
                                     <FontAwesomeIcon
                                     icon={faArrowRight}
                                     className="mx-2"
                                     style={{ fontSize: '1.5rem', color: '#933CAC' }}
                                 />
                                }
                                {image.photo && (
                                
                                       
                                        <img
                                            src={URL.createObjectURL(image.photo)}
                                            alt="preview"
                                            className="img-fluid rounded"
                                            style={{ maxWidth: '45%' }}
                                        />
                                
                                )}
                            </div>
                            
                            <button
                                className="text-decoration-none btn btn-primary sliderButton2 rounded-2 mt-2 col-md-2"
                                onClick={() => document.getElementById(`fileInput-${index}`).click()}
                            >
                                <FontAwesomeIcon icon={faFileImage}/>
                                <span className="ms-2">{_('upload')}</span>
                               
                            </button>

                            <input
                                id={`fileInput-${index}`}
                                type="file"
                                accept="image/*"
                                onChange={(event) => handleImageChange(index, event)}
                                style={{ display: 'none' }}
                            />

                            <div className="col-md-2">
                                <input
                                    type="text"
                                    className="form-control mt-3"
                                    placeholder={_('add_activity_number')}
                                    value={image.number}
                                    onChange={(event) => handleNumberChange(index, event)}
                                />
                            </div>

                            <div className="form-check form-switch mt-2">
                                <input
                                    type="checkbox"
                                    className="form-check-input"
                                    checked={image.status}
                                    onChange={() => handleStatusChange(index)}
                                />
                                <label className="form-check-label">
                                    {image.status ? _('active') : _('passive')}
                                </label>
                            </div>

                            <div className="mt-2">
                                <button className="btn me-2 btn-primary bi bi-trash text-decoration-none btn btn-primary rounded-2 sliderButton" onClick={() => setDeleteModal(index)}>
                                    <span className="ms-2">{_('delete')}</span>
                                </button>
                                {index > 0 && <button className="me-2 text-decoration-none btn btn-primary rounded-2 bi bi-arrow-up sliderButton" onClick={() => moveImage(index, -1)}>
                                <span className="ms-2">{_('up')}</span>
                                </button>}
                                {index < images.length - 1 && <button className="text-decoration-none btn btn-primary rounded-2 bi bi-arrow-down sliderButton" onClick={() => moveImage(index, 1)}>
                                <span className="ms-2">{_('down')}</span>
                                </button>}
                            </div>
                        </div>
                    </div>
                ))}
               <div className="d-flex justify-content-between">
                    <div className="d-flex">
                        <button className="text-decoration-none btn btn-primary sliderButton1 rounded-2" onClick={addNewImage}>{_('add_new_image')}</button>
                    </div>
                    {
                        images.length>0 &&
                        <div className="d-flex">
                        <button className="text-decoration-none btn btn-primary sliderButton1 rounded-2" onClick={handleSubmit}>{_('save')}</button>
                    </div>
                    }
                </div>
            </div>
            <Modal show={deleteModal!==null} onHide={() => setDeleteModal(null)}>
                <Modal.Header closeButton style={{ background: "#fff" }}>
                    <Modal.Title
                        style={{ fontSize: 16 }}
                    ><span className="homeText">{_("delete")}</span></Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <h6 className="homeText">{_("sure")}</h6>
                    </div>
                    <div className="col-12 d-flex justify-content-center align-items-center">
                        <div className="row">
                            <div className="col text-end" onClick={(e) => removeImage(deleteModal)}>
                                <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                    <i className="bi bi-check-circle-fill"></i>
                                </a>
                            </div>
                            <div className="col text-end" onClick={() => setDeleteModal(null)}>
                                <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                    <i className="bi bi-x-circle-fill"></i>
                                </a>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Footer />
        </div>
    );
};

export default Adds;