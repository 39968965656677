import Footer from "./Footer";
import ".././index.css";
import { _ } from "../config/languages/i18n";
import globals from '../config/globals/globals';
import images from "../images/images";
import { Helmet } from "react-helmet";

const ErrorPage = ({ setUser }) => {
	return (
	<>
		<Helmet>
			<meta name="robots" content="noindex"/>
			<link rel="alternate" href={window.location.href} hreflang="tr"/>
			<link rel="canonical" href={window.location.href} hreflang="tr"/>
		</Helmet>
		<div className="bg container-fluid" style={{ backgroundImage: `url(${images.background})` }} >
            <img src={images.top_corner} className="figure-img img-fluid rounded position-absolute top-0 start-0" alt="..." width="150px" />
            <img src={images.bottom_corner} className="figure-img img-fluid rounded position-absolute bottom-0 end-0" style={{ maxWidth: '150px' }} />
			<img src={images.Vidipass_TR_bilet_icin_white} className="figure-img img-fluid rounded position-absolute top-0 end-0 m-4" style={{ maxWidth: '150px' }} />
            <div className="container d-flex justify-content-center align-items-center bg2 mb-5 mt-5">
				<div className="row">
					<div className="col-md-6 d-flex justify-content-center align-items-center">
						<img src={images.error_man} className="img-fluid"  />
					</div>
					<div className="col-md-6 d-flex justify-content-center align-items-center">
						<div className=" justify-content-center ">
							<div className="d-flex justify-content-center">
								<h3 className="panel-title text-white mt-5">{_('error')}</h3>
							</div>
							<div className="d-flex justify-content-center mt-4">
								<h5 className="title text-white">{_('bad_request')}</h5>
							</div>
							<div className="d-flex justify-content-center  mt-4">
								<a className="text-white" style={{textDecoration: "none"}} href={globals.url}>vidipass.com</a>
							</div>		
						</div>		
					</div>
				</div>		
			</div>
            <Footer />
        </div>
	</>
	)
}
export default ErrorPage