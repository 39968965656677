import af from './af.json'; //Afrikanca
import ar from './ar.json'; //Arapça
import az from './az.json'; //Azerice
import bg from './bg.json'; //Bulgarca
import bs from './bs.json'; //Boşnakça
import cy from './cy.json'; //Çince (Basitleştirilmiş)
import de from './de.json'; //Almanca
import el from './el.json'; //Yunanca
import en from './en.json'; //İngilizce
import es from './es.json'; //İspanyolca
import fi from './fi.json'; //Fince
import fr from './fr.json'; //Fransızca
import he from './he.json'; //İbranice
import hi from './hi.json'; //Hintçe
import hr from './hr.json'; //Hırvatça
import hu from './hu.json'; //Macarca
import it from './it.json'; //İtalyanca
import ja from './ja.json'; //Japonca
import kk from './kk.json'; //Kazakça
import ko from './ko.json'; //Korece
import lb from './lb.json'; //Lüksenburgca
import mk from './mk.json'; //Makedonca
import ms from './ms.json'; //Malayca (Malezya Dili)
import nl from './nl.json'; //Felemenkçe
import nn from './nn.json'; //Norveççe
import pt from './pt.json'; //Portekizce
import ro from './ro.json'; //Romence
import ru from './ru.json'; //Rusca
import sq from './sq.json'; //Arnavutça
import sr from './sr.json'; //Sırpça
import sv from './sv.json'; //İsveççe
import tk from './tk.json'; //Türkmence
import tr from './tr.json'; //Türkçe
import tw from './tw.json'; //Çinçe (Geleneksel) - Tayvanca
import uk from './uk.json'; //Ukraynaca
import { Cookies } from "react-cookie"

var defaultLang = 'tr';

const cookies = new Cookies();

var lang = cookies.get("language");

if (typeof lang !== "undefined") {
    defaultLang = lang;
}

export function _(name, params = {}) {
    if (defaultLang == 'tr') {
        if (typeof tr[name] != 'undefined') {
            return tr[name];
        } else {
            return name;
        }
    } 

    else if (defaultLang == 'af') {
        if (typeof af[name] != 'undefined') {
            return af[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'ar') {
        if (typeof ar[name] != 'undefined') {
            return ar[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'az') {
        if (typeof az[name] != 'undefined') {
            return az[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'bg') {
        if (typeof bg[name] != 'undefined') {
            return bg[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'bs') {
        if (typeof bs[name] != 'undefined') {
            return bs[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'cy') {
        if (typeof cy[name] != 'undefined') {
            return cy[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'de') {
        if (typeof de[name] != 'undefined') {
            return de[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'el') {
        if (typeof el[name] != 'undefined') {
            return el[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'es') {
        if (typeof es[name] != 'undefined') {
            return es[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'fi') {
        if (typeof fi[name] != 'undefined') {
            return fi[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'fr') {
        if (typeof fr[name] != 'undefined') {
            return fr[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'he') {
        if (typeof he[name] != 'undefined') {
            return he[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'hi') {
        if (typeof hi[name] != 'undefined') {
            return hi[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'hr') {
        if (typeof hr[name] != 'undefined') {
            return hr[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'hu') {
        if (typeof hu[name] != 'undefined') {
            return hu[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'it') {
        if (typeof it[name] != 'undefined') {
            return it[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'ja') {
        if (typeof ja[name] != 'undefined') {
            return ja[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'kk') {
        if (typeof kk[name] != 'undefined') {
            return kk[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'ko') {
        if (typeof ko[name] != 'undefined') {
            return ko[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'lb') {
        if (typeof lb[name] != 'undefined') {
            return lb[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'mk') {
        if (typeof mk[name] != 'undefined') {
            return mk[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'ms') {
        if (typeof ms[name] != 'undefined') {
            return ms[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'nl') {
        if (typeof nl[name] != 'undefined') {
            return nl[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'nn') {
        if (typeof nn[name] != 'undefined') {
            return nn[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'pt') {
        if (typeof pt[name] != 'undefined') {
            return pt[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'ro') {
        if (typeof ro[name] != 'undefined') {
            return ro[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'ru') {
        if (typeof ru[name] != 'undefined') {
            return ru[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'sq') {
        if (typeof sq[name] != 'undefined') {
            return sq[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'sr') {
        if (typeof sr[name] != 'undefined') {
            return sr[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'sv') {
        if (typeof sv[name] != 'undefined') {
            return sv[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'tk') {
        if (typeof tk[name] != 'undefined') {
            return tk[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'tw') {
        if (typeof tw[name] != 'undefined') {
            return tw[name];
        } else {
            return name;
        }
    }

    else if (defaultLang == 'uk') {
        if (typeof uk[name] != 'undefined') {
            return uk[name];
        } else {
            return name;
        }
    }

    else {
        if (typeof en[name] != 'undefined') {
            return en[name];
        } else {
            return name;
        }
    }
};