
const authReducer = (state = {auth: null}, action) => {
    switch (action.type) {
        case "REGISTER":
        localStorage.setItem('auth', JSON.stringify(action.payload))  
        return{
            ...state,
            auth: action.payload
        }

        case "LOGIN":
        localStorage.setItem('auth', JSON.stringify(action.payload))
        
        return{
            ...state,
            auth: action.payload
        }

        case "ACTIVATION":
        localStorage.setItem('auth', JSON.stringify(action.payload))
        
        return{
            ...state,
            auth: action.payload
        }

        case "CODESENDAGAIN":
        localStorage.setItem('auth', JSON.stringify(action.payload))  
        return{
            ...state,
            auth: action.payload
        }

        case "PASSWORD":
        localStorage.setItem('auth', JSON.stringify(action.payload))
    
        return{
            ...state,
            auth: action.payload
        }

        case "NEWPASSWORD":
        localStorage.setItem('auth', JSON.stringify(action.payload))
    
        return{
            ...state,
            auth: action.payload
        }

        case "PROFILEEDIT":
        localStorage.setItem('auth', JSON.stringify(action.payload))
    
        return{
            ...state,
            auth: action.payload
        }

        case "CHANGEPASSWORD":
        localStorage.setItem('auth', JSON.stringify(action.payload))
    
        return{
            ...state,
            auth: action.payload
        }

        case "LOGOUT":
        localStorage.clear();
        return{
            ...state,
            auth: null
        }

        case "SIGN":
        localStorage.setItem('auth', JSON.stringify(action.payload))
    
        return{
            ...state,
            auth: action.payload
        }

    
        default:
            return state;
    }

}
export default authReducer;