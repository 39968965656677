import React, { useState, useEffect} from 'react';
import ".././index.css";
import globals from '../config/globals/globals';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Cookies } from "react-cookie";
import { _ } from "../config/languages/i18n";
import { useDispatch } from "react-redux";
import axios from "axios";
import TagManager from 'react-gtm-module'

const Footer = () => {
    const dispatch = useDispatch();
    const cookies = new Cookies()
    const [lang, setLang] = useState(cookies.get("language"));
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const langArray = [
        { langCode: "af", langName: "Afrikaans (Afrikaans)" },
        { langCode: "sq", langName: "Shqiptare (Albania)" },
        { langCode: "ar", langName: "عربي (Arabic)" },
        { langCode: "az", langName: "Azərbaycan (Azerbaijan)" },
        { langCode: "bs", langName: "Bosanski (Bosnian)" },
        { langCode: "bg", langName: "български (Bulgarian)" },
        { langCode: "cy", langName: "中国方言 (Chinese - Simplified)" },
        { langCode: "tw", langName: "中國方言 (Chinese - Traditional)" },
        { langCode: "hr", langName: "Hrvatski (Croatian)" },
        { langCode: "nl", langName: "Nederlands (Dutch)" },
        { langCode: "en", langName: "English" },
        { langCode: "fi", langName: "Suomalainen (Finnish)" },
        { langCode: "fr", langName: "Française (French)" },
        { langCode: "de", langName: "Deutsche (German)" },
        { langCode: "el", langName: "Ελληνικά (Greek)" },
        { langCode: "he", langName: "עִברִית (Hebrew)" },
        { langCode: "hu", langName: "Magyar (Hungarian)" },
        { langCode: "hi", langName: "हिंदी (Indian)" },
        { langCode: "it", langName: "Italiano (Italian)" },
        { langCode: "ja", langName: "日本 (Japanese)" },
        { langCode: "kk", langName: "қазақ (Kazakh)" },
        { langCode: "ko", langName: "한국인 (Korean)" },
        { langCode: "lb", langName: "Lëtzebuergesch (Luxembourgish)" },
        { langCode: "mk", langName: "македонски (Macedonian)" },
        { langCode: "ms", langName: "Melayu (Malay)" },
        { langCode: "nn", langName: "Norsk (Norwegian)" },
        { langCode: "pt", langName: "Português (Portuguese)" },
        { langCode: "ro", langName: "Română (Romania)" },
        { langCode: "ru", langName: "Русский (Russian)" },
        { langCode: "sr", langName: "Сıрпца (Serbian)" },
        { langCode: "es", langName: "Español (Spanish)" },
        { langCode: "sv", langName: "Svenska (Swedish)" },
        { langCode: "tr", langName: "Türkçe (Turkish)" },
        { langCode: "tk", langName: "Türkmenler (Turkmen)" },
        { langCode: "uk", langName: "український (Ukrainian)" }
    ];
    const date = new Date();
    const year = date.getFullYear();

    if (!lang) {
        setLang(lang)
        cookies.set('language', 'tr', { 'path': '/' });
        window.location.reload(false);
    }

    function langSelect(e) {
        setLang(e.target.value)
        cookies.set('language', e.target.value, { 'path': '/' });
        window.location.reload(false);
    }

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser) {
           HTTP.post("/updateLanguage", { language: lang }, { headers: { Authorization: `bearer ${getuser.token}` } }).then(res=>{
            if(res.data.user.language) {
                cookies.set('language', res.data.user.language, { 'path': '/' });
            }
           })
        }
    }, []);


    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <footer className="foot footer fixed-bottom">
            <div className="container py-1">
                <div className="row" style={{fontSize: 12}}>
                <div className="col-6 footLink" onClick={()=>gtmTag('copyright_logo')}>
                        <a className="footLink" href={"http://kaminovasyon.com"} target="_blank">© 2023 - {year}, Vidipass®</a>
                    </div>

                    <div className="col-6 footRight">
                        <span  onClick={()=>gtmTag('language_logo')} className="footLink" data-bs-toggle="dropdown" aria-expanded="false"><FontAwesomeIcon icon={faGlobe} /> | </span>
                        <ul className="dropdown-menu scroll-menu" aria-labelledby="dropdownMenuButton1">
                            {langArray.map(item => (
                                <button className="dropdown-item" value={item.langCode} style={{ fontSize: 13, fontWeight: lang === item.langCode && 'bold' }} onClick={langSelect}>{item.langName}</button>
                            ))}
                        </ul>
                        <a  onClick={()=>gtmTag('legal_logo')} className="footLink" href={globals.url + "i/legal"}>{_('legal')} | </a>
                        <a  onClick={()=>gtmTag('collaboration_logo')} className="footLink" href={globals.url + "i/collaboration"}>{_('collaboration')} | </a>
                        <a  onClick={()=>gtmTag('contact_logo')} className="footLink" href={globals.url + "i/contact"}>{_('contact')}</a>
                    </div>
                </div>
            </div>
        </footer>
    );
}

export default Footer