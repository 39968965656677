import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import { _ } from "../config/languages/i18n";
import "bootstrap/dist/css/bootstrap.min.css";
import images from "../images/images";
const Support = () => {
    const infos = [
        {
            logo: "logo_1",
            info: "support_info_1"
        },
        {
            logo: "logo_2",
            info: "support_info_2"
        },
        {
            logo: "logo_3",
            info: "support_info_3"
        },
        {
            logo: "logo_4",
            info: "support_info_4"
        },
        {
            logo: "logo_5",
            info: "support_info_5"
        }
    ];
    return (
        <div className="support-page">
            <Navbar />
            <div className="container mt-5">
                <div className="row mt-1">
                    {infos.map((val, index) => (
                        <div
                            className="col-sm-12 col-md-6 col-lg-4 col-xl-3 mb-4"
                            key={index}
                        >
                            <div className="card-back rounded p-1">
                                <div className="card rounded border-0">
                                    <img
                                        width='100%'
                                        height='auto'
                                        src={images[val.logo]}
                                        className="card-img-top rounded"
                                    />
                                    <div className="card-body">
                                       
                                        <span
                                            style={{ fontSize: 14 }}
                                            className="text-muted"
                                            dangerouslySetInnerHTML={{ __html: _(val.info) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default Support;

