import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";

const Records = ({ setUser }) => {
	const navigate = useNavigate();
	const [email, setEmail] = useState("")
	const [username, setusername] = useState("")
	const [password, setPassword] = useState("")
	const [phone, setPhone] = useState("")
	const [sloganNumber, setSloganNumber] = useState("")
	const cookies = new Cookies()
	const divRef = useRef(null)

	useEffect(() => {
		const lang = cookies.get("language")
		if (lang == 'tr') {
			const sloganNumber = (Math.floor(Math.random() * 3) + 1);
			setSloganNumber(sloganNumber)
		} else {
			const sloganNumber = (Math.floor(Math.random() * 3) + 4);
			setSloganNumber(sloganNumber)
		}
	}, []);

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
	}, [divRef.current])

	const tagManagerArgs = {
		gtmId: 'GTM-PQCRDTTD',
	}

	useEffect(() => {
		TagManager.initialize(tagManagerArgs)
		window.dataLayer = [];
		window.dataLayer.push({
			page: {
				url: window.location.href,
				title: 'Records Page'
			},
			event: 'records_page'
		})
	}, [])

	return (
		<div >
			<Helmet>
				<title>Vidipass | {_("ticket_records")}</title>
				<meta name="description" content={_("meta_content_11")}/>
				<meta name="robots" content="noindex"/>
				<link rel="alternate" href={window.location.href} hreflang="tr"/>
				<link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
			<Navbar />
			<div className="container mb-5 mt-5">
				<div className="d-flex flex-lg-row flex-column rounded-2">
					<div className="card card1 order-2 me-1 mb-1">
						<div className="row justify-content-start">
							<div className="col-md-8 col-10">
								<div className="card-body d-flex row">
									<h1 style={{fontSize:20}} className="title">{_('records')}</h1>
									<hr />
								</div>
								{/* <div className="card-body d-flex col">
									<h6 className="text-muted me-3">01 Ocak 2024</h6>
									<h6 className="">Giriş yapıldı.</h6>
								</div>
								<div className="card-body d-flex col">
									<h6 className="text-muted me-3">01 Ocak 2024</h6>
									<h6 className="">Giriş yapıldı.</h6>
								</div> */}
							</div>
						</div>
					</div>
					<div className="card card2 order-1 me-1 mb-1">
						{
							sloganNumber == 1 ? <img src={images.slogan_1} width='100%' height='auto' className="rounded-1" alt="slogan 1" /> :
							sloganNumber == 2 ? <img src={images.slogan_2} width='100%' height='auto' className="rounded-1" alt="slogan 2" /> :
							sloganNumber == 3 ? <img src={images.slogan_3} width='100%' height='auto' className="rounded-1" alt="slogan 3" /> :
							sloganNumber == 4 ? <img src={images.slogan_4} width='100%' height='auto' className="rounded-1" alt="slogan 4" /> :
							sloganNumber == 5 ? <img src={images.slogan_5} width='100%' height='auto' className="rounded-1" alt="slogan 5" /> :
							<img src={images.slogan_6} width='100%' height='auto' className="rounded-1" alt="slogan 6" />
						}
					</div>
				</div>
			</div>
			<span ref={divRef} />
			<Footer />
		</div>
	)
}

export default Records