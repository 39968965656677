import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { _ } from '../config/languages/i18n';
import globals from '../config/globals/globals';
import images from "../images/images";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const Legal = () => {
    const tagManagerArgs={
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(()=>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title:'Legal Page'
              },
            event:'legal_page'
        })
    },[])
    const cookies = new Cookies()
    var lang = cookies.get("language")
    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("legal_terms")}</title>
                <meta name="description" content={_("meta_content_06")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
                <div className="row">
                    <div className="col card">
                        <div className="panel-heading">
                            <h1 style={{fontSize:25}} className="panel-title">{_("terms_of_use")}</h1>
                        </div>
                        {(lang !== 'tr') && <p className="text-justify">{_('legal_pages_are_in_turkish')}</p>}
                        <p className="text-justify">Yasal konularla ilgili sayfalar Türkçe’dir. Bu konudaki bilgilerin gerekirse farklı bir dile çevirisinin yaptırılması, okunması, anlaşılması ve onaylanması yalnızca kullanıcının sorumluluğundadır. Anlaşılmaması veya kabul edilmeyen herhangi bir kısmı bulunması durumunda, bu hizmet kullanılmamalıdır. Kullanılması, okunup anlaşıldığı ve tamamen kabul edildiği anlamına gelmektedir.</p>
                        <p>Bu İnternet tabanlı site ve beraberindeki hizmetlerin sahibi KAM İNOVASYON BİLİŞİM REKLAMCILIK A.Ş.’dir. Hizmetlerdeki her türlü değişiklik, organizasyon, kullanım ve tasarruf yetkisi yalnızca KAM İNOVASYON BİLİŞİM REKLAMCILIK A.Ş.’ye aittir.</p>
                        <p>Lütfen siteyi kullanmadan önce kullanım koşullarını dikkatle okuyunuz. Siteye girmek ve kullanmakla sitenin kullanım koşullarını ve sözleşme maddelerini kabul etmiş olursunuz.</p>
                        <p>Bu İnternet sitesinin herhangi bir sayfasına girilmesi halinde aşağıda belirtilen şartlar kabul edilmiş sayılır. Bu şartların kabul edilmemesi durumunda internet sitesine girilmemelidir.</p>
                        <p>1. GENEL ŞARTLAR <br />
                            1.1. İşbu sözleşme www.vidipass.com adresli sitenin (Vidipass) tek sahibi olan KAM İNOVASYON BİLİŞİM REKLAMCILIK A.Ş. (“KAM”) ile site kullanıcısı (gerçek veya tüzel kişiler) arasındaki anlaşmanın metnidir. <br />
                            1.2. İşbu sözleşmede KAM İNOVASYON BİLİŞİM REKLAMCILIK A.Ş. kısaca “KAM” şeklinde anılmaktadır. <br />
                            1.3. İşbu sözleşmede www.vidipass.com adresine sahip web sitesi ve bağlantılı uygulamalar da dahil içerdiği hizmetler kısaca “Vidipass” şeklinde anılmaktadır. <br />
                            1.4. İşbu sözleşmede KAM tarafından sunulan bu web sitesi (vidipass.com) de dahil her türlü ürün ve hizmet kısaca “hizmet” veya tamamını kapsayacak şekilde çoğul olarak “hizmetler” şeklinde anılmaktadır. <br />
                            1.4.1. Bu hizmetlere Vidipass da dahildir. <br />
                            1.5. İşbu sözleşmede gerçek kişiler “bireysel kullanıcı”, tüzel kişiler “kurumsal kullanıcı”, her türlü ziyaretçi ve (ya) kullanıcı ise “kullanıcı” şeklinde anılmaktadır. <br />
                            1.6. İşbu sözleşme metni pek çok farklı dilde bulunabilir; ancak anlaşmazlık durumunda yalnızca Türkçe metin geçerlidir. <br />
                            1.7. Site ve mobil uygulamalar da dahil herhangi bir yolla sunulan hizmetleri kullanan kişi, ilgili hizmet (ler) i ilk defa kullanım anından itibaren geçerlilik kazanan kullanım koşullarını kabul etmiş sayılır. <br />
                            1.7.1. Haber vererek veya haber vermeksizin, sözleşmede değişiklik yapma hakkı yalnızca KAM'a aittir. <br />
                            1.7.2. KAM, yasal sözleşmeyi (kullanım koşullarını), değişiklikleri çevrimiçi (online) yayınlamak koşulu ile her an değiştirme hakkını saklı tutar. <br />
                            1.7.3. Çevrimiçi yayınlanan değişikliklerden zamanında haberdar olmak için kullanım koşullarını düzenli olarak takip etmek, kullanıcının sorumluluğundadır. Değişikliklerden dolayı, kullanıcılar, zarara uğradıklarını, yanıldıklarını, hataya düştüklerini ileri süremez ve hiçbir şekilde hak talep edemez. <br />
                            1.7.4. Yapılan değişikliklerden sonra hizmet(ler)i kullanmaya devam eden kişi, kullanım koşullarındaki değişiklikleri kabul etmiş sayılır. <br />
                            1.7.5. KAM’a ait herhangi bir hizmetin kullanılması bu sözleşmenin bütün maddelerinin tamamen kabul edildiği anlamına gelir. <br />
                            1.7.6. Siteye üye olunması bu sözleşmenin üyelik şartları ile ilgili bütün maddelerinin tamamen kabul edildiği anlamına gelir. <br />
                            1.7.7. Hizmet(ler) kullanılırken, her nokta hayatın doğal akışına ve çağa uygun olarak genel geçer örneklere yakın şekilde tasarlanmaya özen gösterilmiş ve her yer doğru anlaşılabilsin diye gerekli çaba gösterilmiş olsa da, her kullanıcı tarafından aynı şekilde anlaşılamayan ya da yanlış anlaşılan yerler olabileceği için bu tarz durumların netleşmesi adına; yeterli araştırmayı yapmak ve gerekirse KAM ile yazılı iletişim sayesinde gerekli açık bilgilendirmeleri edinmiş ve buna uygun olarak ilerlemiş olmak tamamen ve sadece kullanıcının sorumluluğundadır. Anlaşılmadığı düşünülen veya emin olunmayan noktalarda deneme amaçlı dahi olsa herhangi bir işlem yapılmamalıdır. Yapılan tüm işlemler kullanıcının tamamen anlayarak ve bilerek yaptığı anlamına gelmektedir. <br />
                        </p>
                        <p>2. HİZMETLERİN KULLANIMI <br />
                            2.1. KAM’ın sunduğu hizmetler, sisteme kayıtlı yetkili kullanıcıların; kendi özgür iradeleriyle 6698 sayılı <a href="https://kaminovasyon.com/yasal/kvk.php" target="_blank">Kişisel Verilerin Korunması Kanunu</a> kapsamında, bilgilendirildiğini kabul ederek, kanunun öngördüğü serbestlik çerçevesinde kişisel verilerin işlenmesi, kişisel verilerin tamamen veya kısmen otomatik olan ya da herhangi bir veri kayıt sisteminin parçası olmak kaydıyla otomatik olmayan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesini açık rızası ile yasalara uygun olarak paylaşmalarına olanak tanımaktadır. Kullanıcı, kişisel verilerin işlenmesini kabul etmediği veya rızasını sonradan geri aldığı takdirde, kullanım koşullarını kabul etmemiş sayılacak ve sisteme erişimi engellenecektir. Bu halde, taraflar arasındaki sözleşmenin kullanıcı tarafından haksız fesih hükümleri uygulanır. Ancak bu olanağın kullanımında kısıtlamalar bulunmaktadır. KAM’ın bu kısıtlama hakkı hiçbir şekilde kullanıcı veya üçüncü kişilere bir hak bahşetmez. Kullanıcı, sisteme kaydettiği verilere ilişkin işbu sözleşme ekinde bulunan aydınlatma metnini okuduğunu ve açık rızasının bulunduğunu beyan etmiştir. <br />
                            2.1.1. Kullanıcılar yazma, yükleme, bağlantısını (linkini) paylaşma da dahil herhangi bir yolla sisteme girdikleri yazı, resim, video, tarih, saat de dahil olmak üzere her türlü bilginin doğruluğundan ve hak sahibi olduğundan sorumludurlar, bu konuda KAM'ın herhangi bir sorumluluğu bulunmamaktadır.  KAM’ın sorumsuzluğu kullanıcı dışında üçüncü kişilere de şamildir.<br />
                            2.1.2. Kullanıcılar, paylaştıkları bilgilerin tamamının kendilerine ait olduğundan ya da yayınlama haklarının kendilerinde bulunması da dahil olmak üzere hiçbir koşulda telif hakkı ihlali içermediğinden emin olmak zorundadırlar ve bundan dolayı, işbu sözleşmenin tarafları veya üçüncü kişiler nezdinde doğabilecek her türlü zarardan sorumludurlar, bu konuda KAM'ın herhangi bir sorumluluğu bulunmamaktadır. <br />
                            2.1.3. Kullanıcı, hiçbir şekilde telif hakkı mahfuz olan bir maddeyi, ticari markayı veya diğer müseccel bilgiyi, hak sahiplerinin önceden vermiş bulunduğu yazılı bir muvafakati olmadan, sisteme (siteye) ekleyemez, dağıtamaz veya kopyalayamaz. Kullanıcı, üçüncü kişilerin hiçbir maddi veya manevi haklarına zarar veremez, aksi halde, bundan doğmuş ve (ya) doğacak zararlardan sorumludur. Kendisinin mevcut olan bir telif hakkının ihlal edildiğine inanan kişilerin bunu kaldırarak hiç yapılmamış sayılacak şekilde önceki hale getirmek de dahil herhangi bir yasal yol ile telafi etmeleri ve bunun ardından da gerekiyorsa, KAM'ın “iletişim” sayfasından KAM yetkilileri ile yazılı olarak iletişime geçmeleri gerekir. <br />
                            2.1.4. KAM ile iletişime geçildiğinde, iletişime geçen kişi beyan etmiş olduğu kendi bilgileri ve iletişim içeriğinde yer alan her konudan tamamen sorumlu olduğunu ve de KAM ile paylaşacağı fikirler ile ilgili hiçbir hak iddia edemeyeceğini kabul etmiş olur. <br />
                            2.1.4.1. Buna göre; iletişime geçen kişinin paylaşacağı fikirlerin patent, lisans ve(ya) telif hakkı da dahil herhangi bir koruma altında olmaması gerekir. İletişime geçen kişi paylaşacağı fikrin özgün olduğunu ve herhangi bir koruma altında bulunmadığını da beyan etmiş sayılır. <br />
                            2.1.4.2. Fikri paylaşan kişi hiçbir şekilde hak iddia edemeyeceğini ve herhangi bir hakkı varsa bunlardan feragat edeceğini net bir şekilde kabul etmiş olur. <br />
                            2.1.4.3. KAM'ın, kendisine ulaştırılan bu fikirlerin tamamını veya bir bölümünü, hiçbir ödemede bulunmadan, haber vermeksizin, aynen veya değiştirerek benimseme ve(ya) kullanma ve de bundan tasarruf etme hakkı vardır. <br />
                            2.1.5. Kullanıcı, içerikten ve içeriğin kullanım şeklinden sorumludur. Kullanıcı veya üçüncü kişiler, sisteme eklenen içeriklerden (yazı, resim, video, tarih, saat vs.) yada kullanma şeklinden dolayı, KAM’ın hiçbir şekilde sorumlu olmadığını kabul ederler. Kullanıcı da dahil üçüncü kişiler içerikten ve kullanım şeklinden dolayı KAM aleyhinde maddi veya manevi zarar iddiası ileri süremezler. <br />
                            2.2. Hizmetlere erişim ve hizmetlerin kullanımı, KAM tarafından belirlenen aşağıdaki esas ve şartlara tabidir. <br />
                            2.2.1. Hizmetlerin kullanımının bir koşulu olarak, kullanıcının, KAM'ın ilgili hizmetine kayıt olması ve bir şifre ve kullanıcı adı (bu kullanıcı adı bir e-posta adresi de içerebilir ve burada "Vidipass kullanıcı adı" önemlidir) belirlemesi şarttır. Kullanıcı KAM'a eksiksiz ve güncel olan doğru kayıt bilgilerini vermek zorundadır. <br />
                            2.2.1.1. KAM, site kullanıcılarına sunduğu bilgilerin doğru olması için her türlü gayreti göstermekle beraber, bilgilerin doğruluğu konusunda, açıktan veya ima yolu ile hiçbir garanti vermez. KAM yanlış veya eksik bilgiden sorumlu değildir. Hizmetler, üçüncü kişiler tarafından sunulan bilgiler de içerebilir. Üçüncü kişiler, bu hizmetlerde yayınlanan materyallerin ulusal ve uluslararası mevzuatla uyumlu olmasını sağlamak ile yükümlüdürler. KAM, bu materyalin doğruluğunu garanti edemez ve materyaldeki hata, ihmal veya eksiklik, yanlış beyan, materyalde vaat edilen hizmetlerin yerine getirilmesinde meydana gelecek bir aksama ve(ya) getirilmesinin aksaması iddiası karşısında ve duyurulan hizmetleri sunan özel veya tüzel kişinin iflası veya kuruluşun tasfiyesi durumlarında doğabilecek maddi ve(ya) manevi kayıp ve(ya) zarar nedeniyle sorumluluk kabul etmeyeceğini açıkça beyan eder. Sunulan bilgilerin doğruluğu ve güncelliği, söz konusu üçüncü şahısların sorumluluğundadır. <br />
                            2.2.2. KAM hizmetlerinin sürekli erişilebilir olması için çalışmaktadır; ancak değişik sebeplerle hizmet(ler)in erişilebilir olmamasından sorumlu değildir. <br />
                            2.2.2.1. Hizmetlere erişim, herhangi bir duyuru yapılmaksızın geçici veya sürekli olarak durdurulabilir. <br />
                            2.2.3. KAM, hizmetlerin kullanılması, kullanılamaması, bünyesinde sunulan bilgiler veya hizmetlere bağlı olarak yapılan eylemler veya alınan kararlar nedeniyle kullanımında meydana gelen aksaklıktan, hizmet(ler)in içerdiği materyalden, hizmet(ler)in kullanımından sonra alınan bir karar veya yapılan bir eylemden doğan, kontrat, haksız fiil veya başka türlü (sınırlama olmadan, iş kaybından doğan zararlar veya kâr kaybı dahil) zararlardan sorumlu değildir. <br />
                            2.2.4. KAM, hizmetlerinde yer alan bağlantılarla (linklerle) ulaşılacak site ve uygulamalarda sunulan bilgiler nedeniyle hiçbir sorumluluk kabul etmez. Söz konusu bağlantılar, açıktan veya ima yolu ile herhangi bir garanti verilmeksizin, kullanıcıya kullanım kolaylığı sağlamak amacıyla verilmiştir. <br />
                            2.3. “Cookie” bilgisayarlara gönderilebilen bir yazılımdır. “Cookie”ler KAM tarafından sunulan hizmetlerin teknik açıdan kullanıcıya fayda sunması için tasarlanabileceği gibi, nasıl kullanıldığına dair bilgileri toplamaya ve yönetmeye de yarayabilir. Bunların kişinin izniyle veya iznine ihtiyaç duyulmadan ilişkilendirilmesi teknik elverişlilik ile ilintilidir ve KAM'ın bu konuda herhangi bir sorumluluğu bulunmamaktadır. <br />
                            2.4. KAM hizmetlerini kullananların hesaplarını neden göstermeksizin askıya alma (dondurma, durdurma) ve(ya) silme ve(ya) engelleme yetkisine sahiptir. <br />
                            2.5. KAM ürünlerinin (mal ve hizmetlerinin) ücretli kısımları bulunabilir, bunlarla ilgili ödemeler ilgili bölümdeki açıklamalarda belirtildiği şekilde gerçekleştirilebilmektedir. <br />
                            2.5.1. Ödemeler sırasında sistem içi krediler satılmakta ve önceden elde edilen bu krediler sistem içerisinde kullanılabilmektedir. <br />
                            2.5.2. Ödemeler banka havalesi veya eft ya da banka kartı veya kredi kartı yoluyla gerçekleştirilebilir. <br />
                            2.5.2.1. Bunların güvenliği için ödemelerde KAM hiçbir şekilde banka kartı veya kredi kartı numaralarını ya da şifrelerini kayıt altına almaz ve böylece olası bir saldırı veya açık durumunda, bu bilgilere erişilememesini sağlamayı hedefler. KAM, ödemenin alınamaması veya eksik alınması ya da güvenlik açısından karşılaşılabilecek herhangi bir sıkıntıdan sorumlu tutulamaz. Bu noktada tüm sorumluluk yalnızca kullanıcıdadır. <br />
                        </p>
                        <p>3. PAZARLAMA MATERYALLERİNİN KULLANIMI <br />
                            3.1. KAM'a ve(ya) hizmetlerine ait logoların, isimlerin, jingleların (kurumsal melodilerin) ve İnternet bağlantılarının dışarıda kullanımı bazı şartlarlar çerçevesinde mümkündür. <br />
                            3.2. KAM resmi İnternet sitesine veya hizmetlerine ait logolar bir bağlantı niteliğinde olabilir; ancak bunlar kullanılan yere verilen bir destek niteliğinde değildir. <br />
                            3.3. Logo, sadece KAM ve(ya) ürünlerine (mal ve hizmetlerine) doğru atıfta bulunulan İnternet siteleri ve televizyonlar da dahil her türlü medya kanalında ve hatta poster ve afişler de dahil olmak üzere pek çok alanda kullanılabilir. Logo, KAM tarafından sponsorluk, destek ve(ya) lisans verildiğini ima edecek şekilde gösterilemez. Yalnızca kullanıcının ve(ya) paylaşmak istediği ilgili bağlantı (URL) adresine dikkat çekilmek üzere kullanılabilir. <br />
                            3.3.1. Bu kullanım, Vidipass için Vidipass ambleminin (logosunun veya ikonunun) kullanımı ile mümkündür. <br />
                            3.4. Logoların, en ve boy oranları, renkleri ve öğeleri dahil olmak üzere, herhangi bir şekilde değiştirilemez, canlandırılamaz, dönüştürülemez ya da başka herhangi bir işlemden geçirilerek, perspektifi ve(ya) iki boyutlu görünümü bozulamaz. <br />
                            3.5. KAM ürünlerini (mal ve hizmetlerini) kötüleyen ve(ya) kanunlardan doğan haklarını ihlal eden ya da herhangi bir ulusal ve(ya) uluslararası bir düzenlemeyi çiğneyen bir İnternet sitesinde, uygulamada ve(ya) medya aracında KAM ve(ya) ürünlerinin logoları kullanılamaz. <br />
                            3.6. Bu kurallar kapsamında, KAM, logolarını kullananlara herhangi bir lisans ya da başka bir hak vermemektedir. KAM, logoların kullanımı konusunda verdiği izni, tek taraflı olarak, kaldırma ya da koşullarını değiştirme hakkına her zaman sahiptir. KAM, bu kurallara uymayan ve(ya) herhangi bir ulusal ve(ya) uluslararası bir düzenlemeyi çiğneme ve(ya) yasalara aykırı kullanma durumlarına karşı yasal işlem başlatma hakkını saklı tutar. <br />
                        </p>
                        <p>4. SORUMLULUK REDDİ <br />
                            4.1. KAM, bu hizmetlerde yer alan bilgilerin tam ve doğru olduğunu veya herhangi bir sayfaya kesintisiz giriş sağlanacağını garanti etmemektedir. İşbu sitede yer alan beyanlar hukuken taahhüt niteliğinde sayılmamaktadır ve bağlayıcı değildir. Bu bilgilerle üçüncü şahısların haklarının ihlal edilmemesi; mülkiyet, satılabilirlik, belirli bir amaç için uygunluk ve(ya) bilgisayar ve(ya) herhangi bir elektronik cihaz virüsü bulunmamasına ilişkin garantiler dahil, ancak bunlarla sınırlı kalmamak kaydıyla, zımnen, açıkça ya da yasal olarak hiçbir garanti vermemektedir. <br />
                            4.2. Hizmetlerde yer alan bilgilerin doğruluğu, yazım hatası, yazılım hatası, aktarım hatası, sistemlerin bozulması veya saldırıya uğraması gibi nedenlerden kaynaklanan hatalara karşı hiçbir garanti verilmemektedir. Bu nedenle bu İnternet sitesinde yer alan elektronik dokümanlardaki hatalardan, eksikliklerden ya da bu dokümanlara dayanılarak yapılan işlemlerden doğacak, doğrudan ve(ya) dolaylı, her türlü maddi ve(ya) manevi zararlar ve masraflardan KAM sorumlu tutulamaz. <br />
                            4.3. Bu İnternet sitesinin ve beraberindeki uygulamaların kullanımı sırasında herhangi bir arıza, hata, eksiklik, kesinti, kusur ve(ya) nakilde gecikme, bilgisayar virüsü veya hat veya sistem arızası sonucu doğrudan ve(ya) dolaylı ortaya çıkan zarar, ziyan ve masraflar da dahil, ancak bunlarla sınırlı olmamak üzere hiçbir zarardan, KAM ve(ya) çalışanı, bu tür bir zarar olasılığından haberdar edilmiş olsalar dahi, sorumlu olmazlar. <br />
                            4.4. Bu bilgiler doğrultusunda, KAM, bu hizmetlerin içeriğinde yer alan bilgilerden ve görsel malzemeden kaynaklanabilecek hatalardan, maddi ve(ya) manevi zararlardan hiçbir şekil ve surette sorumlu değildir. <br />
                            4.5. Hizmetlerde yer alan tüm bilgiler önceden haber verilmeksizin değiştirilebilir. KAM dilediği zaman, hizmetlerin herhangi bir bölümünü iptal edebilir, değiştirebilir, haber vermeksizin hizmetlerdeki bazı özellikleri ve(ya) bilgileri ve(ya) hizmet(ler)in bazı bölümlerine erişimi sınırlandırabilir. <br />
                            4.6. KAM, hizmetlerinin virüs ve saldırı önlemlerini almış olsa dahi, bu konuda hiçbir garanti vermemektedir. <br />
                            4.6.1. Herhangi bir bilgi, belge, uygulama vb. aktarmadan ve(ya) indirmeden önce virüslerden korunma konusunda gerekli önlemlerin kullanıcı tarafından alınması tavsiye edilmektedir. <br />
                            4.7. KAM hizmetlerini kullananların bilgilerini gizli tutmaya özen gösterse de, saldırılar ve teknik problemler gibi istenmeyen durumlar ya da mahkeme kararı ile emniyet güçleriyle paylaşılma zorunluluğu gibi durumlarda ve bunlarla kısıtlı kalmaksızın herhangi bir nedenden dolayı bunların açığa çık(arıl)masından sorumlu tutulamaz. <br />
                            4.8. KAM'ın hiçbir kullanıcı ile özel hiçbir ilişkisi yoktur ve kullanıcıya karşı herhangi bir mutemet ve(ya) vekil sorumluluğu da mevcut değildir. Kullanıcı, "Hangi kullanıcılar siteye erişim sağlıyor; hangi kullanıcı içeriğine site aracılığıyla erişiyor; bir kullanıcı içeriğinin diğer bir kullanıcı üzerindeki etkisi nedir; kullanıcı, kullanıcı içeriğini nasıl yorumlayabilir veya kullanabilir ya da bir kullanıcı içeriğinden etkilenen diğer bir kullanıcı ne gibi eylemler yapabilir" gibi hususlarla ilgili olarak KAM'ın hiçbir kontrol mekanizması bulundurmak ve(ya) bunlarla ilgili hiçbir şekilde takibat yapma zorunluluğu olmadığını kabul etmek zorundadır. <br />
                            4.8.1. Kullanıcı, kendisinin site aracılığıyla ele geçirdiği ve(ya) elde edemediği kullanıcı içeriğinin tüm yükümlülüklerinden dolayı KAM'ın mesul olmadığını kabullenmek mecburiyetindedir. Site, bazı kişilerin itici veya uygunsuz bulduğu bir içeriğe sahip olabilir veya kullanıcılarını bu tip unsurlar içeren sitelere yönlendirebilir. KAM, sitede bulunan veya site üzerinden erişilen bir içerikle ilgili temsilcilik yapmaz ve KAM'ın, site üzerinden erişilen veya sitede bulunan bir materyalin doğruluğu, telif hakkına uygun olup olmadığı, yasallığı veya topluma uygunluğu konularında bir araştırma yapma mecburiyeti ve yükümlülüğü yoktur. Hizmetler, içerik, site ve herhangi bir yazılım; ister sarih (açık) ister zımni (örtük), herhangi bir sınırlama ve ticarete elverişlilik garantisi olmayan, belirli bir amaca uygunluk gibi ya da herhangi bir ihlali önleyici sınırlama koymadan, tamamen "olduğu gibi" ilkesine göre sunulmuştur. <br />
                            4.8.2. Bazı yargı alanları, zımni ticari elverişlilik garantisi, belirli bir amaca uygunluk ve hakların ihlal edilmemesi gibi garantiler öngörmeyebilmektedir. Yasaların izin verdiği ölçüde tüm bu garantiler KAM ve hizmetleri açısından kapsam dışında tutulmaktadır. <br />
                            4.8.3. KAM ve(ya) tedarikçileri ya da dağıtıcıları, işbu şartlarda ve ek şartlarda açıkça belirtilenler haricinde, hizmetlerle ilgili hiçbir belirli vaatte bulunmamaktadır. Hizmetlerde yer alan içerikler, hizmetlerin belirli işlevleri, bunların güvenilirliği, kullanılabilirliği ve(ya) ihtiyaçları karşılaması hakkında hiçbir taahhütte bulunulmamaktadır. <br />
                            4.9. KAM, kendisi üzerinden yapılan ve(ya) kendi sitesinden bağlantı yapılarak ulaşılan, herhangi bir web sitesi tarafından gerçekleştirilen herhangi bir iletişimin gizliliği veya dokunulmazlığı konusunda garanti vermez. KAM, elektronik posta adreslerinin, kayıtların ve kimlik bilgilerinin, disk alanının, danışmaların, haberleşmelerin, gizli veya ticari sır niteliğindeki bilgilerin, KAM'ın kendisinin ve(ya) hizmet aldığı üçüncü şahısların (tüzel veya özel kişilerin) donanımı üzerinde depolanan ve(ya) site tarafından erişilen bilgisayar ağı üzerinden iletilen ve(ya) hizmetlerin kullanıcı tarafından değişik şekilde kullanılması ile ilgili herhangi bir içerikten sorumlu değildir. <br />
                            4.10. Hizmet içerisinde tüzel veya özel kişinin kaydettiği her türlü veriden yalnızca ekleyenin kendisi sorumlu olmasından dolayı; silme, saklama ve bununla sınırlı kalmamakla beraber her türlü işlem hakkı Kişisel Verilerin Korunması Kanunu da dahil tüm yasalara uygun olmak zorunda olup, tüm sorumluluğu yalnızca ekleyene aittir ve KAM’a rücu edilemez. <br />
                            4.11. Kullanım şartlarıyla ilgili ortaya çıkabilecek ihtilaflarda, münhasıran Türk Hukuku kapsamında Ankara Mahkemeleri ve İcra Daireleri yetkilidir. <br />
                        </p>
                        <p>5. İHTİLAF DURUMU <br />
                            5.1. Bu sözleşmenin en güncel halinin tamamının kabul edilmemesi durumunda hizmetin kullanılmaması gerekir. Kullanılması durumunda ise, kullanan kişi (gerçek veya tüzel) tarafından bütün maddelerin eksiksiz olarak kabul edildiği anlaşılır. Buna göre; doğacak her türlü problemden, zarardan, cezadan yalnızca kullanıcı sorumludur. KAM hiçbir bölgede, hiçbir koşulda, hiçbir şekilde sorumlu tutulamaz ve hakkında cezai işlem yapılamaz. <br />
                            5.2. Anlaşmazlık durumunda, yalnızca Türkçe olan sözleşme esastır ve Türk hukukuna göre münhasıran Ankara mahkemeleri ve icra daireleri yetkilidir. Dava ve takipler yetkili Ankara mahkemeleri ve icra daireleri dışında görülemez. Açılan davalardaki avukat ücretleri de dahil hiçbir masraf KAM'a ödetilemez ve bunların tamamından karşı taraf sorumlu olur. <br />
                        </p>
                        <p>5. İHTİLAF DURUMU <br />
                            5.1. Bu sözleşmenin en güncel halinin tamamının kabul edilmemesi durumunda hizmetin kullanılmaması gerekir. Kullanılması durumunda ise, kullanan kişi (gerçek veya tüzel) tarafından bütün maddelerin eksiksiz olarak kabul edildiği anlaşılır. Buna göre; doğacak her türlü problemden, zarardan, cezadan yalnızca kullanıcı sorumludur. KAM hiçbir bölgede, hiçbir koşulda, hiçbir şekilde sorumlu tutulamaz ve hakkında cezai işlem yapılamaz. <br />
                            5.2. Anlaşmazlık durumunda, yalnızca Türkçe olan sözleşme esastır ve Türk hukukuna göre münhasıran Ankara mahkemeleri ve icra daireleri yetkilidir. Dava ve takipler yetkili Ankara mahkemeleri ve icra daireleri dışında görülemez. Açılan davalardaki avukat ücretleri de dahil hiçbir masraf KAM'a ödetilemez ve bunların tamamından karşı taraf sorumlu olur. <br />
                        </p>
                        <p>6. EK KOŞULLAR <br />
                            6.1. Vidipass Web Sitesi (Mobil Site ve Uygulamalar da Dahil) Kullanım Koşulları <br />
                            6.1.1. Vidipass, sözleşme şartları da dahil olmak üzere, site ve site uzantılarında mevcut her tür koşulu ve bilgiyi önceden herhangi bir ihtara hacet olmaksızın değiştirme hakkını saklı tutar. Değişiklikler sitede yayım anında yürürlüğe girer. <br />
                            6.1.2. İşbu siteye girilmesi veya sitenin kullanılması sonucunda ortaya çıkabilecek her tür ihtilaf Türk kanunlarına tabi olup, ihtilafların çözümünde Ankara Mahkemeleri münhasıran yetkilidir. <br />
                            6.1.3. Vidipass web sitesinde site yönetimi tarafından yerleştirilmiş olan bilgilerin doğruluk ve güncelliğini sürekli şekilde kontrol etmektedir; ancak tüm itinalı çalışmalara rağmen, web sayfalarındaki bilgiler fiili değişikliklerin gerisinde kalabilir. Vidipass’in web sitesinde bulunan materyal ve bilgiler siteye verildiği anda sunulmuştur, ilgili hizmetin güncel durumu ile sitede yer alan durumu arasında farklılık olabilir. Web sitesindeki bilgilerin, güncelliği, doğruluğu, şartları, kalitesi, performansı, pazarlanabilirliği, belli bir amaca uygunluğu ve Vidipass’in web sayfasında yer alan ve bunlarla sınırlı olmayan, bunlarla bağlantılı veya bağımsız diğer bilgi ve ürünlere (mal ve hizmetlere) etkisi ile tamlığı hakkında herhangi bir sarih (açık) ya da zımni (gizli) garanti verilmemekte ve taahhütte bulunulmamaktadır. <br />
                            6.2. Web Sayfasında (Mobil Sayfalar ve Uygulamalar da Dahil) Değişiklik Yapma Hakkı <br />
                            6.2.1. Vidipass işbu site ve site uzantısında mevcut her tür hizmet, ürün, siteyi kullanma koşulları ile sitede sunulan bilgileri önceden bir ihtara gerek olmaksızın değiştirme, siteyi yeniden organize etme, yayını durdurma hakkını saklı tutar. Değişiklikler sitede yayım anında yürürlüğe girer. Sitenin kullanımı ya da siteye giriş ile bu değişiklikler de kabul edilmiş sayılır. Bu koşullar bağlantı (link) verilen diğer web sayfaları ve uygulamalar için de geçerlidir. <br />
                            6.2.1.1. Vidipass, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti, silinme, kayıp, işlemin veya iletişimin gecikmesi, bilgisayar virüsü, iletişim hatası, hırsızlık, imha veya izinsiz olarak kayıtlara girilmesi, değiştirilmesi veya kullanılması hususunda herhangi bir sorumluluk kabul etmez. <br />
                            6.3. Web Sayfalarına Bağlantı (Link) Verilmesi <br />
                            6.3.1. Bu site üzerinden başka sitelere bağlantı (link) verilmesi mümkündür, Vidipass link verilen sayfalardaki bilgilerin doğruluğunu garanti etmemekte, herhangi bir taahhütte bulunmamaktadır. <br />
                            6.3.1.1. Bu sayfaların bir kısmı Vidipass dışındaki kişi veya kuruluşlar tarafından ve o kişi veya kuruluşların sorumluluğunda düzenlenmekte olup, ilgili sayfaların ve materyallerin kullanımı Vidipass yönetiminden bağımsız şekilde, bu kişi veya kuruluşun koşul ve şartları tahtında gerçekleşiyor olabilir. Vidipass bu sayfa ve bağlantılı hizmetlerin kullanımı konusunda bir sorumluluk almamakta ve herhangi bir tavsiyede bulunmamaktadır. Bu siteleri kullanımla doğabilecek zararlar kullanıcının kendi sorumluluğundadır. <br />
                            6.3.1.2. Vidipass yönetimi, bu tür bağlantı verilen sayfalara erişimi, kendi yazılı muvafakatine bağlayabileceği gibi, Vidipass yönetiminin uygun görmeyeceği bağlantılara erişimi her zaman kesebilir. Kesip kesmeme hakkı her ne kadar Vidipass’e ait olsa da, kestiği veya kesmediği için sorumlu tutulamaz. <br />
                            6.4. Fikri Mülkiyet Haklarına İlişkin Uyarı <br />
                            6.4.1. Vidipass Web Sitesi’nin sahibi KAM'dır. Bu sayfada bulunan bilgiler, yazılar, resimler, markalar, slogan ve diğer işaretler ile sair fikri mülkiyet haklarına ilişkin bilgilerin korunmasına yönelik programlarla, sayfa düzeni ve işbu web sayfasının sunumu Vidipass’in ya da Vidipass’in lisans aldığı kuruluşların mülkiyetindedir. Vidipass’den önceden yazılı muvafakat alınmaksızın, işbu web sayfasındaki bilgilerin ya da bu sayfaya ilişkin her tür veritabanı, web sitesi, kodlama ve her türlü içeriğin kısmen ya da tamamen kopyalanması, değiştirilmesi, yayımlanması, çevrimiçi (online) ya da diğer bir medya kullanılmak suretiyle gönderimi, dağıtımı, satılması yasaktır. Bu sayfadaki bilgilerin kısmen kopyalanması, basılması ancak ticari olmayan kişisel ihtiyaç için mümkündür. <br />
                            6.5. Sorumluluğun Sınırlandırılması <br />
                            6.5.1. Vidipass, bu siteye girilmesi, sitenin ya da sitedeki bilgilerin ve diğer verilerin programların vs. kullanılması sebebiyle, sözleşmenin ihlali, haksız fiil, ya da başkaca sebeplere binaen, doğabilecek doğrudan ya da dolaylı hiçbir zarardan sorumlu değildir. Vidipass, sözleşmenin ihlali, haksız fiil, ihmal veya diğer sebepler neticesinde; işlemin kesintiye uğraması, hata, ihmal, kesinti hususunda herhangi bir sorumluluk kabul etmez. <br />
                            6.5.2. Bu siteye ya da bağlantı (link) verilen sitelere girilmesi ya da sitenin kullanılması ile Vidipass’in ziyaret ve kullanım sonucunda, doğabilecek her tür sorumluluktan, mahkeme ve diğer masraflar da dahil olmak üzere her tür zarar ve talep hakkından beri kılındığı kabul edilmektedir. <br />
                        </p>
                        <p>7. ÖDEMELER <br />
                            7.1. Hizmet Ödemeleri <br />
                            7.1.1. Bu hizmetin kullanımı için ödemeler, belirlenmiş ekran(lar) aracılığıyla kullanıcıların beyanatına dayalı olarak sanal veya fiziki kredi/banka kartlarıyla veya eft/havale yoluyla yapılır. <br />
                            7.1.2. Ödeme kapsamında ne kadarlık bir süre için hangi hizmetlerin alınacağı belirlenir; ancak indirim uygulama ve bununla sınırlı olmaksızın her türlü ek koşul dahil olmak üzere bunlarda sınırlamaya gitme hakkı KAM'da dır. <br />
                            7.1.3. Ödemenin ardından ilk otuz (30) günlük süre içerisinde cayma hakkını kullandırma da KAM'ın inisiyatifindedir. Daha sonrası için herhangi bir ödemeye ilişkin iade durumu söz konusu değildir ve hizmetin kullanılıp kullanılmadığının önemi olmaksızın bu konuda KAM'dan herhangi bir talepte bulunulamaz. <br />
                            7.1.4. Hizmet için ilgili döneme ait ödeme yapıldıktan sonra, bir sonraki ödeme zamanına ulaşıldığında hizmetin devamı için KAM tarafından belirlenecek aynı veya yeni tutarın ödenmemesi halinde, hizmet aniden kesilebilir veya KAM tarafından ilgili hizmet için belirlenen özel bir ek süre bulunuyorsa, bu süre boyunca hizmet kesintisiz devam ettirilir. Ödemenin yapılması için tanınan bu ek süre içerisinde gerekli ödemenin tamamının yapılmaması halinde, hizmet askıya alınır ve kullanımına izin verilmez. <br />Varsa belirlenen bu ek süre de dahil olacak şekilde, ödemenin yapılacağı zamana kadar geçen süre boyunca hizmet durdurulmuş olur. Durdurulan bir hizmetin yeniden devreye alınması için ödeme yapılması gerekir. Bu ödeme, hem ek sürede kullanılan hizmetin süresini, hem durdurulmuş olan süreyi hem de devamında kullanılacak olan süreyi kapsayacak şekilde gerçekleştirilir. Dondurulmuş süre için ek bir ödeme talep edip etmemek KAM tarafından ilgili hizmet için özel olarak belirlenir. Durdurulmuş olan hizmet, bir yıl boyunca askıya alınmış şekilde saklanır ve ödeme yapılması halinde kaldığı yerden devam ettirilir. Dolayısıyla, bir yıl süresince veri kaybı yaşanmaması adına sağlanan bu özel imkan kötüye kullanılamaz. <br />Ödeme yapılmadan geçen bir yıllık sürenin ardından, hizmet tamamen kesilir ve yeniden kullanılmak istendiğinde, ilk kez üye olunuyormuş gibi tüm verilerden arındırılmış bir şekilde sıfırdan hizmet alımı gerçekleştirilebilir. Buna göre; bir yıl boyunca verilerin saklanması ve sonrasında silinmesi esastır. Bunun uygulanıp uygulanmaması KAM’ın inisiyatifindedir. Bundan dolayı yaşanacak herhangi bir kayıptan veya olumsuzluktan hiçbir koşulda KAM sorumlu tutulamaz. <br />
                            7.1.5. Hizmetin içeriğindeki her bir uygulama ve kullanılabilir kapasite alanı ayrı ayrı ücretlendirilir. Bununla birlikte, ücretler dönemsel veya kalıcı olarak değişkenlik gösterebilir. <br />
                            7.1.6. Hizmetin kesintisiz devam etmesi açısından, ödemesi yapılmış hizmetin süresinin bitiminden önce süre uzatımı için ödeme yapılması ve benzer şekilde kapasitenin kullanımı doğrultusunda gerekliyse alanın artırılması noktasında gerekli hassasiyetin gösterilmesi önemle tavsiye edilmekte olup, tüm sorumluluk kullanıcıdadır ve asla KAM sorumlu tutulamaz. <br />
                            7.1.7. Hizmetin devamlılığı bakımından ödeme ve tüm diğer koşullara uyulması önemlidir ve sorumluluk bütünüyle kullanıcıya aittir. <br />
                        </p>
                        <p>© vidipass.com da dahil olmak üzere KAM İNOVASYON BİLİŞİM REKLAMCILIK A.Ş.’ye ait bütün internet sitelerinde yer alan ve sayfalara ve de hizmetlere ilişkin tüm haklar saklıdır.</p>
                        <p>İlk Yayınlanma Tarihi: 19.05.2023 | Sondan Bir Önceki Güncellemenin Yayınlanma Tarihi: 15.05.2023 | Son Güncellemenin Yayınlanma Tarihi: 19.05.2023</p>
                        <table width="100%">
                            <tbody>
                                <tr>
                                    <td width="120px;" align="left">
                                        <a href={globals.url} title={globals.url}>
                                            <img width="100" height='auto' src={images.vidipass_logo} className="figure-img img-fluid rounded" alt="vidipass logo" />
                                        </a>
                                    </td>
                                    <td align="center">Vidipass bir KAM İnovasyonudur.</td>
                                    <td width="120px;" align="right">
                                        <a href="https://kaminovasyon.com" title="kaminovasyon.com" target="_blank">
                                            <img height="50" width='auto' src="https://kaminovasyon.com/images/logo.png" alt="kam logo" />
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Legal