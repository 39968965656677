import axios from "axios";
import { toast } from "react-toastify";
import globals from "../../config/globals/globals";
import { _ } from "../../config/languages/i18n";
import images from "../../images/images"


const HTTP = axios.create({ baseURL: globals.apiUrl });

export const registerAction = (authData) => async (dispatch) => {
    await HTTP.post('/register', authData).then(res => {
        dispatch({ type: 'REGISTER', payload: res.data })
        const data = res.data
        if (data.result == true) {
              toast(
                <div>
                    {data.message}
                </div>
                , {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false
                
                })
                setTimeout(() => window.location.href=globals.url + "i/activation", 3000);        
        }
        if (data.result == false) {
            if (data.user) {
                if (!data.user.activated_at) {
                    if (data.errors.email) {
                        toast(_('complete_activation'), {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            
                            })
                            setTimeout(() => window.location.href=globals.url + "i/activation", 3000);  
                    } else {
                        toast(_('complete_activation'), {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            
                            })
                            setTimeout(() => window.location.href=globals.url + "i/activation", 3000);  
                    }
                }
                if (data.user.activated_at) {
                    if (data.errors.email) {
                        toast(data.errors.email[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/login", 3000);  
                    } else {
                        toast(data.errors.phone[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/login", 3000);  
                    }
                }
            } else {
                    toast(data.message, {
                        position: "top-right",
                        autoClose: 3000,
                        hideProgressBar: false,
                        closeOnClick: false,
                        pauseOnHover: false,
                        draggable: false,
                        progress: undefined,
                        theme: "light",
                        closeButton:false,
                        type: "error"
                        })
            }
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const loginAction = (authData) => async (dispatch) => {
    await HTTP.post('/login', authData).then(res => {
        dispatch({ type: 'LOGIN', payload: res.data })
        const data = res.data
        if (data.result == false) {
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false,
                type: "error"
                
                })
        }
        if (data.result == true) {
            if (!data.user.activated_at) {
                toast(_('registration_is_not_yet_complete'), {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false,
                    type: "error"
                    
                    })
                    setTimeout(() => window.location.href=globals.url + "i/register", 3000);   
            }
            if (data.user.activated_at) {
                toast(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false
                    
                    })
                    if(localStorage.getItem('reference'))
                    {
                        setTimeout(() => window.location.href=JSON.parse(localStorage.getItem('reference')), 3000);  
                    }
                    else{
                        setTimeout(() => window.location.href=globals.url, 3000);  
                    }
                    
            }
        }

    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const signAction = (authData) => async (dispatch) => {
    await HTTP.post('/signInOtherAccounts', authData).then(res => {
        dispatch({ type: 'SIGN', payload: res.data })
        const data = res.data
        if (data.result == false) {
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false,
                type: "error"
                
                })
        }
        if (data.result == true) {
            toast( 
                <div>
                    
                    {data.message}
                </div>
                 ,{
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false
                
                })
                if(localStorage.getItem('reference'))
                {
                    setTimeout(() => window.location.href=JSON.parse(localStorage.getItem('reference')), 3000);  
                }
                else{
                    setTimeout(() => window.location.href=globals.url, 3000);  
                }
                
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const activationAction = (authData) => async (dispatch) => {
    await HTTP.post('/activation', authData).then(res => {
        const data = res.data
        if (data.token) {
            dispatch({ type: 'ACTIVATION', payload: res.data })
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false
                
                })
                if(localStorage.getItem('reference'))
                {
                    setTimeout(() => window.location.href=JSON.parse(localStorage.getItem('reference')), 3000);  
                }
                else{
                    setTimeout(() => window.location.href=globals.url, 3000);  
                }
        }
        if (!data.token) {
            if(data.url){
                toast(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false,
                    type: "error"
                    
                    })
                    setTimeout(() => window.location.href=globals.url + "i/register", 3000); 
            }else {
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false,
                type: "error"
                
                })
            }
        }
    }
    ).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const codeSendAgainAction = (authData) => async (dispatch) => {
    await HTTP.post('/codeSendAgain', authData).then(res => {
        dispatch({ type: 'CODESENDAGAIN', payload: res.data })
        const data = res.data
        if (data.result == true) {
                toast( 
                    <div>
                        {data.message}
                    </div>
                    ,{
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false
                    
                    })
                    setTimeout(() => window.location.href=globals.url + "i/activation", 3000);
        }
        if (data.result == false) {
            if (data.user) {
                if (!data.user.activated_at) {
                    if (data.errors.email) {
                            toast(data.errors.email[0], {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                                closeButton:false,
                                type: "error"
                                })
                                setTimeout(() => window.location.href=globals.url + "i/activation", 3000);  

                    } else {
                        toast(data.errors.phone[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/activation", 3000); 
                    }
                }
                if (data.user.activated_at) {
                    if (data.errors.email) {
                        toast(data.errors.email[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/login", 3000);  
                    } else {
                        toast(data.errors.phone[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/login", 3000);  
                    }
                }
            } else {
                toast(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false,
                    type: "error"
                    })
            }
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const codeSendAgainPasswordAction = (authData) => async (dispatch) => {
    await HTTP.post('/codeSendAgain', authData).then(res => {
        dispatch({ type: 'CODESENDAGAIN', payload: res.data })
        const data = res.data
        if (data.result == true) {
                toast(<div>
                    {data.message}
                    </div>
                   ,{
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false
                    
                    })
                    setTimeout(() => window.location.href=globals.url + "i/newPassword", 3000);
        }
        if (data.result == false) {
            if (data.user) {
                if (!data.user.activated_at) {
                    if (data.errors.email) {
                            toast(data.errors.email[0], {
                                position: "top-right",
                                autoClose: 3000,
                                hideProgressBar: false,
                                closeOnClick: false,
                                pauseOnHover: false,
                                draggable: false,
                                progress: undefined,
                                theme: "light",
                                closeButton:false,
                                type: "error"
                                })
                                setTimeout(() => window.location.href=globals.url + "i/newPassword", 3000);  

                    } else {
                        toast(data.errors.phone[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/newPassword", 3000); 
                    }
                }
                if (data.user.activated_at) {
                    if (data.errors.email) {
                        toast(data.errors.email[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/login", 3000);  
                    } else {
                        toast(data.errors.phone[0], {
                            position: "top-right",
                            autoClose: 3000,
                            hideProgressBar: false,
                            closeOnClick: false,
                            pauseOnHover: false,
                            draggable: false,
                            progress: undefined,
                            theme: "light",
                            closeButton:false,
                            type: "error"
                            })
                            setTimeout(() => window.location.href=globals.url + "i/login", 3000);  
                    }
                }
            } else {
                toast(data.message, {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false,
                    type: "error"
                    })
            }
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const passwordAction = (authData) => async (dispatch) => {
    await HTTP.post('/forgotPassword', authData).then(res => {
        dispatch({ type: 'PASSWORD', payload: res.data })
        const data = res.data
        if (data.result == false) {
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false,
                type: "error"
                
                })
        }
        if (data.result == true) {
                toast(
                    <div>
                    {data.message}
                </div> , {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false
                    
                    })
                    setTimeout(() => window.location.href=globals.url + "i/newPassword", 3000);
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const NewPasswordAction = (authData) => async (dispatch) => {
    await HTTP.post('/checkRememberCode', authData).then(res => {
        const data = res.data
        if (data.result == false) {
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false,
                type: "error"
                
                })
        }
        if (data.result == true) {
                toast(
                    <div>
                    {data.message}
                    </div>
                    , {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton:false
                    })
                    setTimeout(() => window.location.href=globals.url + "i/login", 3000);  
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const ProfileEditAction = (authData) => async (dispatch) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    await HTTP.post('/updateProfile', authData, {
        headers: { Authorization: `bearer ${getuser.token}` },
    }).then(res => {
        dispatch({ type: 'PROFILEEDIT', payload: res.data })
        const data = res.data
        if (data.result == true) {
            toast(
                <div>
                {data.message}
                </div>
                , {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false
                })
                setTimeout(() => window.location.href=globals.url + "i/profile", 3000);  
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}

export const ChangePasswordAction = (authData) => async (dispatch) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    await HTTP.post('/changePassword', authData, {
        headers: { Authorization: `bearer ${getuser.token}` },
    }).then(res => {
        const data = res.data
        if (data.result == true) {
            localStorage.removeItem("auth");
            toast(
                <div>
                {data.message}
                </div>
                , {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false
                })
                setTimeout(() => window.location.href=globals.url + "i/login", 3000); 
        } else {
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false,
                type: "error"
                })
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton:false,
            type: "error"
            })
    }
    )
}


export const ProfileEditEmailAction = (authData) => async (dispatch) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    await HTTP.post('/updateProfileEmail', authData, {
        headers: { Authorization: `bearer ${getuser.token}` },
    }).then(res => {
        dispatch({ type: 'PROFILEEDIT', payload: res.data })
        const data = res.data
        if (data.result == true) {
            toast(
                <div>
                    {data.message}
                </div>
                , {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton: false
                })
            setTimeout(() => window.location.href = globals.url + "i/profile", 3000);
        }
       
    }).catch(() => {
       
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton: false,
            type: "error"
        })
    }
    )
}

export const ProfileEditPhoneAction = (authData) => async (dispatch) => {
    const getuser = JSON.parse(localStorage.getItem("auth"));
    await HTTP.post('/updateProfilePhone', authData, {
        headers: { Authorization: `bearer ${getuser.token}` },
    }).then(res => {
       
        const data = res.data
        if (data.result == true) {
            dispatch({ type: 'PROFILEEDIT', payload: res.data })
            toast(
                <div>
                    {data.message}
                </div>
                , {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: false,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "light",
                    closeButton: false
                })
            setTimeout(() => window.location.href = globals.url + "i/profile", 3000);
        }
    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton: false,
            type: "error"
        })
    }
    )
}

export const saveContact = (authData) => async (dispatch) => {
    await HTTP.post('/saveContact', authData).then(res => {
        const data = res.data
        if (data.result == true) {
            toast(
                <div>
                {data.message}
                </div>
                ,{
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false
                })
                if(data.contact.type == 0) {
                    setTimeout(() => window.location.href=globals.url + "i/contact", 3000); 
                } else {
                    setTimeout(() => window.location.href=globals.url + "i/collaboration", 3000); 
                }
                
        } else {
            toast(data.message, {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: false,
                pauseOnHover: false,
                draggable: false,
                progress: undefined,
                theme: "light",
                closeButton:false,
                type: "error"
                })
        }

    }).catch(() => {
        toast(_('connection_error'), {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
            closeButton: false,
            type: "error"
        })
    }
    )
}