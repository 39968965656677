import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import { useState, useEffect, useRef } from "react";
import { _ } from '../config/languages/i18n';
import { passwordAction } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import globals from '../config/globals/globals';
import axios from "axios";

const ForgotPassword = ({ setForgot }) => {
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [country_code, setCountry] = useState("TR");
    const [phone_number, setPhoneNumber] = useState("");
    const [type, setType] = useState("");
    const dispatch = useDispatch();
    const formData = new FormData();
    const cookies = new Cookies()
    const [sloganNumber, setSloganNumber] = useState("")
    const divRef = useRef(null)
    const lang = cookies.get("language")
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtc(new Date().getTimezoneOffset()/-60)
	},[])
	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])
    useEffect(() => {
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);
    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Forgot Password Page'
            },
            event: 'forgot_password_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div >
            <Helmet>
                <title>Vidipass | {_("forgot_password")}</title>
                <meta name="description" content={_("forgot_password")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
                <div className="card col-md-6 offset-md-3">
                    <div className="row justify-content-center my-auto">
                        <div className="col-md-8 col-10">
                            <div className="row justify-content-center mb-3">
                                <img width={25} height={'auto'} className="w-25 mb-2" src={images.forgot_password_icon} alt="forgot password" />
                                <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("forgot_password")}</h1>
                            </div>
                            <ul className="nav nav-tabs nav-fill" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="faq_tab_1-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_1" type="button" role="tab" aria-controls="faq_tab_1" aria-selected="true">
                                        <div className="d-flex flex-column lh-lg">
                                            <i className="bx bxs-plane-alt"></i>
                                            <span className="homeText">{_("phone")}</span>
                                        </div>
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="faq_tab_2-tab" data-bs-toggle="tab" data-bs-target="#faq_tab_2" type="button" role="tab" aria-controls="faq_tab_2" aria-selected="false">
                                        <div className="d-flex flex-column lh-lg">
                                            <i className="bx bxs-shopping-bag" ></i>
                                            <span className="homeText">{_("e_mail")}</span>
                                        </div>
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade active show" id="faq_tab_1" role="tabpanel" aria-labelledby="faq_tab_1-tab">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        setCountry("");
                                        setPhone("");
                                        setType("");
                                        setPhoneNumber("");
                                        formData.append("phone", phone);
                                        formData.append("phone_number", phone);
                                        formData.append("country_code", country_code);
                                        formData.append("type", 2);
                                        formData.append("lang", lang);
                                        formData.append("ip_address", ip);
                                        formData.append("device_name", device);
                                        formData.append("utc", utc);
                                        dispatch(passwordAction(formData));
                                    }}>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("phone")}</label>
                                            <PhoneInput
                                                placeholder={_("phone")}
                                                value={phone}
                                                onChange={setPhone}
                                                className="headerInput"
                                                onCountryChange={setCountry}
                                                defaultCountry="TR"
                                            />
                                        </div>
                                        <div onClick={()=>gtmTag('send_button_forgot_password')}  className="row justify-content-center my-3 px-3">
                                            <button disabled={!phone} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("send")}</button>
                                        </div>
                                    </form>
                                </div>
                                <div className="tab-pane fade" id="faq_tab_2" role="tabpanel" aria-labelledby="faq_tab_2-tab">
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        setEmail("");
                                        setType("");
                                        formData.append("email", email);
                                        formData.append("type", 1);
                                        formData.append("lang", lang);
                                        formData.append("ip_address", ip);
                                        formData.append("device_name", device);
                                        formData.append("utc", utc);
                                        dispatch(passwordAction(formData));
                                    }}>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("e_mail")}</label>
                                            <input type="text" value={email} onChange={(e) => setEmail(e.target.value)} placeholder={_("e_mail")} className="form-control" />
                                        </div>
                                        <div onClick={()=>gtmTag('send_button_forgot_password')} className="row justify-content-center my-3 px-3">
                                            <button disabled={!email} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("send")}</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="card card2 order-1 me-1 mb-1">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}

            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default ForgotPassword