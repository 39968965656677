import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import Loader from "../components/Loader";
import TagManager from 'react-gtm-module'
import { useDispatch } from "react-redux";
import { saveContact } from "../redux/actions/auth";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import globals from "../config/globals/globals";
import { Helmet } from "react-helmet";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import AddActivity from "./AddActivity";

const Panel = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const HTTPNumber = axios.create({ baseURL: `http://localhost:8000`});
    const [number, setNumber] = useState('')
    const [activity, setActivity] = useState('')
    const [placeModal, setPlaceModal] = useState(false)
    const [cityId, setCityId] = useState('')
    const formData = new FormData();
    const [userId, setUserId] = useState("");
    const [token, setToken] = useState("");
    const [place, setPlace] = useState('');
    const [userType, setUserType] = useState('');
    


    const getNumber = () =>{
        setLoading(true)
        HTTPNumber.get(`/s/api/${number}`)
        .then(
            (res)=>{
                if (res.data.result){
                    setLoading(false)
                    setActivity(res.data.activity)
                    setPlaceModal(true)
                }
            }
        )
    }


    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser?.result) {
            setUserId(getuser.user.id);
            setUserType(getuser.user.type)
            setToken(getuser.token)
        }
    }, []);

    const addPlace = () =>{
        formData.append("place_title", activity?.place?.title);
        formData.append("address", activity?.place?.address);
        formData.append("lat", activity?.place?.lat);
        formData.append("lon", activity?.place?.lon);
        formData.append("city_id", cityId);
        formData.append("created_by", userId);

        HTTP.post("/createPlace", formData,
        { headers: { Authorization: `bearer ${token}` } }
        ).then((res) => {
            console.log(res)
                if (res.data.result) {
                    setPlaceModal(false);
                    setPlace(res.data.place)
                }
            })
    }
    console.log(place)
    return (
        <div >
           {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mt-5 col-md-3">
                        <div className="d-flex justify-content-center card">
                            <div className="text-center mt-1" style={{fontWeight: 'bold'}}> {_("activity")}
                            {(userType == 3 || userType == 2) &&
                                <>
                                    {userType == 3 &&
                                    <>
                                        <a href={globals.url + 'i/addActivity'} className="text-decoration-none d-flex justify-content-center my-3 px-3">
                                            <button className="text-decoration-none btn btn-primary cardButton">{_("add_activity")}</button>
                                        </a>
                                    </>
                                    }
                                    <a href={globals.url + 'i/activities'}  className="text-decoration-none d-flex justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton">{_("activities")}</button>
                                    </a>
                                </>
                            }
                            </div>
                            </div>
                            <div className="d-flex justify-content-center card">
                            {(userType == 3 || userType == 5 ) &&
                            <div className="text-center mt-1" style={{fontWeight: 'bold'}}> {_("finance")}
                           
                                <>
                                    <a href={globals.url + 'i/walletTransactions'} className="text-decoration-none d-flex justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton">{_("wallet_transactions")}</button>
                                    </a>
                                    <a href={globals.url + 'i/dailyTransactions'}  className="text-decoration-none d-flex justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton">{_("daily_pos_transactions")}</button>
                                    </a>
                                    <a href={globals.url + 'i/activityTransactions'}  className="text-decoration-none d-flex justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton">{_("activity_pos_transactions")}</button>
                                    </a>
                                </>
                            
                            </div>
                            }
                        </div>
                        <div className="d-flex justify-content-center card">
                            {userType == 3 &&
                             <>
                                <div className="text-center mt-1" style={{fontWeight: 'bold'}}> {_("website")}
                                    <a href={globals.url + 'i/adds'}  className="text-decoration-none d-flex justify-content-center my-3 px-3">
                                                <button className="text-decoration-none btn btn-primary cardButton">{_("visual_editing")}</button>
                                    </a>
                                    <a href={globals.url + 'i/contactMessages'} className="text-decoration-none d-flex justify-content-center my-3 px-3">
                                        <button className="text-decoration-none btn btn-primary cardButton">{_("contact")}</button>
                                    </a>
                                </div>
                            </>
                            }
                        </div>
                    </div>
                </>
            <Footer />
        </div>
    )
}

export default Panel