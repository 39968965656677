import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import 'react-phone-number-input/style.css'
import { useState, useEffect} from "react";
import { _ } from "../config/languages/i18n";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import 'react-phone-number-input/style.css'
import globals from "../config/globals/globals";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal } from "react-bootstrap";
import moment from "moment";
import { getCities } from "../config/cities/cities";

const AddActivity = () => {
    const [loading, setLoading] = useState(false);
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const HTTPNumber = axios.create({ baseURL: `https://actividi.com`});
    const [url, setUrl] = useState('')
    const [activity, setActivity] = useState('')
    const formData = new FormData();
    const [userId, setUserId] = useState("");
    const [token, setToken] = useState("");
    const cities = getCities();
    const [city, setCity] = useState(0);
    const [urlModal, setUrlModal] = useState(false)

    const getNumber = () =>{
        setLoading(true)
        HTTPNumber.get(`/s/api/${url.split("/").pop()}`)
        .then(
            (res)=>{
                if (res.data.result){
                    setLoading(false)
                    checkActivity(res.data.activity)
                }
            }
        )
    }

    const checkActivity = (val) =>{
        setLoading(true)
        formData.append("extra", `https://actividi.com/a/${url.split("/").pop()}`);        
        HTTP.post("checkActivity", formData,
            { headers: { Authorization: `bearer ${token}` }}
        ).then(
            (res)=>{
                if (!res.data.result){
                    setLoading(false)
                    setActivity('')
                    setUrlModal(res.data.activity.number)
                    
                }else{
                    setLoading(false)
                    setActivity(val)

                }
            }
        )
    }
   
    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem("auth"));
        if (getuser?.result) {
            setUserId(getuser.user.id);
            setToken(getuser.token)
        }
    }, []);

    const addActivity = () =>{
        setLoading(true)
        formData.append("place_title", activity?.place?.title);
        formData.append("address", activity?.place?.address);
        formData.append("lat", activity?.place?.lat);
        formData.append("lon", activity?.place?.lon);
        formData.append("city_id", city);
        formData.append("created_by", userId);
        formData.append("activity_title", activity.title);
        formData.append("activity_photo", 'https://actividi.com/api/' + 'activity/photo/' + activity.id + '?' + new Date(activity.updated_at).getTime());
        formData.append("description", activity.description);
        formData.append("category_id", activity.category_id);
        formData.append("online_url", '');
        formData.append("start_time", moment(new Date(activity.start_time)).format("DD.MM.YYYY HH:mm"));
        formData.append("end_time", moment(new Date(activity.end_time)).format("DD.MM.YYYY HH:mm"));
        formData.append("is_paid", 1);
        formData.append("is_online", 0);
        formData.append("currency_id", 24);
        formData.append("hashtags", '');
        formData.append("utc", '+3');
        formData.append("status", 1);
        formData.append("comment", '');
        formData.append("meta_description", '');
        formData.append("meta_keywords", '');
        formData.append("type", 1);
        formData.append("extra", url);
        formData.append("activity_status", 1);
        formData.append("activity_fee", activity.activity_fee);
        HTTP.post("/activities/add", formData,
        { headers: { Authorization: `bearer ${token}` } }
        ).then((res) => {
                if(res.data.result){
                    setLoading(false)
                    window.location.href = globals.url + 'a/' + res.data.activity.number;
                }
            })
    }

    return (
        <div >
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mb-5 mt-5">
                        <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                            <div className="card card1 order-2 me-1 mb-1 mt-5">
                                <div className="row justify-content-center my-auto pb-3">
                                    <div className="col-md-8 col-10">
                                        <div className="text-center mb-3 mt-3">
                                            <h1 style={{fontSize:16}} className="homeText">{_("add_activity")}</h1>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">Actividi Url</label>
                                            <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} className="form-control" />
                                        </div>
                                        <div className="row justify-content-center my-3 px-3">
                                            <button disabled={!url} onClick={()=>getNumber()} className="text-decoration-none btn btn-primary cardButton col-6">{_("add_activity")}</button>
                                        </div>
                                        {
                                            activity &&
                                            <div className="form-group mb-2 mt-2">
                                                <label className="form-control-label text-muted">{_("select_city")}</label>
                                                <select
                                                    type="text"
                                                    className={"form-control bg-white headerInput"}
                                                    style={{ float: "left" }}
                                                    onChange={(e) => setCity(e.target.value)}
                                                    selected={city}
                                                    value={city} >
                                                    <option value="0" key={0}>{_("select_city")}</option>
                                                    {cities.map((city, i) => {
                                                        return (
                                                            <option value={city.key} key={i + 1}>
                                                                {city.value[0] + city.value.slice(1).toLowerCase()}
                                                            </option>
                                                        );
                                                    })}
                                                </select>
                                            </div>
                                        }
                                        {
                                            (activity && city!=0) &&
                                            <>
                                                <div className="form-group mt-3">
                                                    <label className="form-control-label text-muted">{_("activity_title")}</label>
                                                    <span className="form-control">{activity.title}</span>
                                                </div>
                                                <div className="justify-content-center mt-3">
                                                    <img
                                                        src={'https://actividi.com/api/' + 'activity/photo/' + activity.id + '?' + new Date(activity.updated_at).getTime()
                                                        }
                                                        className="rounded-2"
                                                        width='100%'
                                                        height='auto'
                                                        alt="activity photo"
                                                    />
                                                </div>
                                                <div className="form-group mb-2 mt-2">
                                                    <label className="form-control-label text-muted">{_("place")}</label>
                                                    <span className="form-control">{activity.place.title}</span>
                                                </div>
                                                <div className="row mt-2 text-center">
                                                    <div className="form-group col-6">
                                                        <div className="text-center">
                                                            <label className="form-control-label text-muted">{_("start_time")}</label>
                                                        </div>
                                                        <div>
                                                            <DatePicker
                                                            disabled
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={5}
                                                            dateFormat="dd.MM.yyyy / HH:mm"
                                                            className="form-control text-start"
                                                            selected={new Date(activity.start_time)}                                           
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-6">
                                                        <div className="text-center">
                                                            <label className="form-control-label text-muted">{_("end_time")}</label>
                                                        </div>
                                                        <div>
                                                            <DatePicker
                                                            disabled
                                                            showTimeSelect
                                                            timeFormat="HH:mm"
                                                            timeIntervals={5}
                                                            dateFormat="dd.MM.yyyy / HH:mm"
                                                            className="form-control  text-start"
                                                            selected={new Date(activity.end_time)} 
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-2 mt-2">
                                                    <label className="form-control-label text-muted">{_("description")}</label>
                                                    <textarea className="form-control" disabled rows="4" cols="50" value={activity.description}></textarea>
                                                </div>
                                                <div className="form-group mb-2 mt-2">
                                                    <label className="form-control-label text-muted">{_("ticket_info")}</label>
                                                    <div className="card-bg rounded mx-4 p-1">
                                                        <div className="row d-none d-md-flex">
                                                            <div className="col-3">
                                                                <span className="ms-1" style={{ color: "#FFFFFF" }}>{_("category")}</span>
                                                            </div>
                                                            <div className="col-3">
                                                                <span className="ms-1" style={{ color: "#FFFFFF" }}>{_("armchair")}</span>
                                                            </div>
                                                            <div className="col-3">
                                                                <span style={{ color: "#FFFFFF" }}>{_("type")}</span>
                                                            </div>
                                                            <div className="col-3 text-center">
                                                                <span style={{ color: "#FFFFFF" }} >{_("fee")}</span>
                                                            </div>
                                                        </div>

                                                    {JSON.parse(activity.activity_fee).map((val) =>{
                                                        return (
                                                            <div  className="card p-1 my-1">
                                                                <div className="row">
                                                                    <div className="col-md-3 d-flex justify-content-center justify-content-md-start align-items-center">
                                                                        <span className="text-start multi-line-text-truncate">{val.activityCategoryName}</span>
                                                                        
                                                                    </div>
                                                                    <div className="col-md-3 d-flex justify-content-center justify-content-md-start align-items-center">
                                                                        <span className="text-start multi-line-text-truncate">{val.activitySeat}</span>
                                                                        
                                                                    </div>
                                                                    <div className="d-block d-md-none" style={{ color: "#933CAC" }}>
                                                                        <hr style={{ height: 2 }} />
                                                                    </div>
                                                                
                                                                    <div className="col-md-6">
                                                                        {val.activityCategoryType.map((ind,i)=> {
                                                                            return (
                                                                                
                                                                                <div className="row">
                                                                                   
                                                                                    <div className="col-6 d-flex justify-content-center justify-content-md-start align-items-center">
                                                                                        <span className="text-start multi-line-text-truncate" >{ind.activityClass}</span>
                                                                                    </div>
                                                                                    <div className="col-6 d-flex justify-content-center align-items-center">
                                                                                        <span className="text-center multi-line-text-truncate" >{ind.activityPrice} TRY</span>
                                                                                    </div>
                                                                                    
                                                                                    {i < val.activityCategoryType.length - 1 ? <div style={{ color: "#000" }}>
                                                                        <hr style={{ height: 1 }} />
                                                                    </div> : ""}
                                                                                </div>
                                                                            );
                                                                        })}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })}
                                                    </div>
                                                </div>
                                                <div className="row justify-content-center my-3 px-3">
                                                    <button onClick={()=>addActivity()} className="text-decoration-none btn btn-primary cardButton col-6">{_("add_activity")}</button>
                                                </div>
                                            </>
                                        }   
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={urlModal} onHide={() => setUrlModal(false)}>
                            <Modal.Body closeButton style={{ background: "#fff" }}>
                                
                                <div className="modal-body row d-flex justify-content-center">
                                <span className="row d-flex text-center justify-content-center mb-3">{_("activity_info")}</span>
                                <div className="d-flex justify-content-center align-items-center px-2 mb-3">
                                    <a href={`${globals.url}a/${urlModal}`}
                                        className="homeText d-flex text-decoration-none border rounded-2 p-2"
                                    >
                                        {`${globals.url}a/${urlModal}`}
                                    </a>
                                </div>
                                   
                                    <button onClick={()=>setUrlModal(false)} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6">{_("close")}</button>
                                </div>
                                
                            </Modal.Body>
                        </Modal>
                </>
            <Footer />
        </div>
    )
}

export default AddActivity