export default {
    // "url": "http://localhost:3000/",
    // "apiUrl": "http://localhost:8000/api",
    // "imgUrl": "http://localhost:8000/uploads/activity-photo/",
    // "imgAdds": "http://localhost:8000/uploads/adds/",

    "url": "https://vidipass.com/",
    "imgUrl": "https://api.vidipass.com/uploads/activity-photo/",
    "imgAdds": "http://api.vidipass.com/uploads/adds/",
    "apiUrl": "https://api.vidipass.com/api"
}