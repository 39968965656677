const cities = [
    {
        "key": "82",
        "value": "Online"
    },
    {
        "key": "1",
        "value": "Adana"
    },
    {
        "key": "2",
        "value": "Adıyaman"
    },
    {
        "key": "3",
        "value": "Afyonkarahisar"
    },
    {
        "key": "4",
        "value": "Ağrı"
    },
    {
        "key": "68",
        "value": "Aksaray"
    },
    {
        "key": "5",
        "value": "Amasya"
    },
    {
        "key": "6",
        "value": "Ankara"
    },
    {
        "key": "7",
        "value": "Antalya"
    },
    {
        "key": "75",
        "value": "Ardahan"
    },
    {
        "key": "8",
        "value": "Artvin"
    },
    {
        "key": "9",
        "value": "Aydın"
    },
    {
        "key": "10",
        "value": "Balıkesir"
    },
    {
        "key": "74",
        "value": "Bartın"
    },
    {
        "key": "72",
        "value": "Batman"
    },
    {
        "key": "69",
        "value": "Bayburt"
    },
    {
        "key": "11",
        "value": "Bilecik"
    },
    {
        "key": "12",
        "value": "Bingöl"
    },
    {
        "key": "13",
        "value": "Bitlis"
    },
    {
        "key": "14",
        "value": "Bolu"
    },
    {
        "key": "15",
        "value": "Burdur"
    },
    {
        "key": "16",
        "value": "Bursa"
    },
    {
        "key": "17",
        "value": "Çanakkale"
    },
    {
        "key": "18",
        "value": "Çankırı"
    },
    {
        "key": "19",
        "value": "Çorum"
    },
    {
        "key": "20",
        "value": "Denizli"
    },
    {
        "key": "21",
        "value": "Diyarbakır"
    },
    {
        "key": "81",
        "value": "Düzce"
    },
    {
        "key": "22",
        "value": "Edirne"
    },
    {
        "key": "23",
        "value": "Elazığ"
    },
    {
        "key": "24",
        "value": "Erzincan"
    },
    {
        "key": "25",
        "value": "Erzurum"
    },
    {
        "key": "26",
        "value": "Eskişehir"
    },
    {
        "key": "27",
        "value": "Gaziantep"
    },
    {
        "key": "28",
        "value": "Giresun"
    },
    {
        "key": "29",
        "value": "Gümüşhane"
    },
    {
        "key": "30",
        "value": "Hakkari"
    },
    {
        "key": "31",
        "value": "Hatay"
    },
    {
        "key": "76",
        "value": "Iğdır"
    },
    {
        "key": "32",
        "value": "Isparta"
    },
    {
        "key": "34",
        "value": "İstanbul"
    },
    {
        "key": "35",
        "value": "İzmir"
    },
    {
        "key": "46",
        "value": "Kahramanmaraş"
    },
    {
        "key": "78",
        "value": "Karabük"
    },
    {
        "key": "70",
        "value": "Karaman"
    },
    {
        "key": "36",
        "value": "Kars"
    },
    {
        "key": "37",
        "value": "Kastamonu"
    },
    {
        "key": "38",
        "value": "Kayseri"
    },
    {
        "key": "79",
        "value": "Kilis"
    },
    {
        "key": "71",
        "value": "Kırıkkale"
    },
    {
        "key": "39",
        "value": "Kırklareli"
    },
    {
        "key": "40",
        "value": "Kırşehir"
    },
    {
        "key": "41",
        "value": "Kocaeli"
    },
    {
        "key": "42",
        "value": "Konya"
    },
    {
        "key": "43",
        "value": "Kütahya"
    },
    {
        "key": "44",
        "value": "Malatya"
    },
    {
        "key": "45",
        "value": "Manisa"
    },
    {
        "key": "47",
        "value": "Mardin"
    },
    {
        "key": "33",
        "value": "Mersin"
    },
    {
        "key": "48",
        "value": "Muğla"
    },
    {
        "key": "49",
        "value": "Muş"
    },
    {
        "key": "50",
        "value": "Nevşehir"
    },
    {
        "key": "51",
        "value": "Niğde"
    },
    {
        "key": "52",
        "value": "Ordu"
    },
    {
        "key": "80",
        "value": "Osmaniye"
    },
    {
        "key": "53",
        "value": "Rize"
    },
    {
        "key": "54",
        "value": "Sakarya"
    },
    {
        "key": "55",
        "value": "Samsun"
    },
    {
        "key": "56",
        "value": "Siirt"
    },
    {
        "key": "57",
        "value": "Sinop"
    },
    {
        "key": "58",
        "value": "Sivas"
    },
    {
        "key": "63",
        "value": "Şanlıurfa"
    },
    {
        "key": "73",
        "value": "Şırnak"
    },
    {
        "key": "59",
        "value": "Tekirdağ"
    },
    {
        "key": "60",
        "value": "Tokat"
    },
    {
        "key": "61",
        "value": "Trabzon"
    },
    {
        "key": "62",
        "value": "Tunceli"
    },
    {
        "key": "64",
        "value": "Uşak"
    },
    {
        "key": "65",
        "value": "Van"
    },
    {
        "key": "77",
        "value": "Yalova"
    },
    {
        "key": "66",
        "value": "Yozgat"
    },
    {
        "key": "67",
        "value": "Zonguldak"
    } 
];

export function getCities() {
    return cities;
};