import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../index.css";
import 'react-phone-number-input/style.css';
import { useState, useEffect } from "react";
import { _ } from "../config/languages/i18n";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import globals from "../config/globals/globals";
import "react-datepicker/dist/react-datepicker.css";
import { Cookies } from "react-cookie";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import images from "../images/images";
import axios from "axios";
import DataTable from 'react-data-table-component';
import { tr, de, enUS, af, ar, az, bg, bs, cy, el, es, fi, fr, he, hi, hr, hu, it, ja, kk, ko, lb, mk, ms, nl, nn, pt, ro, ru, sq, sr, sv, uk, zhTW } from "date-fns/locale";
import { format } from "date-fns";
import { DateRange } from "react-date-range";


const ActivityTransactions = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const [token, setToken] = useState("");
    const timestamp = new Date();
    const cookies = new Cookies();
    const [openDate, setOpenDate] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [lang] = useState(cookies.get("language"));
    const [totalAmount, setTotalAmount] = useState("");
    const [activityTransactions, setActivityTransactions] = useState([])
    const [search, setSearch] = useState("");
    const [ticketNumber, setTicketNumber] = useState();
    const [date, setDate] = useState([
        {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            endDate: new Date(new Date()),
            key: "selection"
        }]);
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    const convertArrayOfObjectsToCSV = (array) => {
        if (array.length === 0) return null;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const quote = '"';
    
        const keys = Object.keys(array[0]);
        let result = '';
    
        // Add column headers
        result += keys.map(key => `${quote}${key}${quote}`).join(columnDelimiter);
        result += lineDelimiter;
    
        // Add rows
        array.forEach(item => {
            result += keys.map(key => {
                let value = item[key] !== undefined && item[key] !== null ? item[key] : '';
                if (typeof value === 'string') {
                    // Escape double quotes in the value and wrap it in double quotes
                    value = value.includes(quote) ? 
                        `${quote}${value.replace(new RegExp(quote, 'g'), `${quote}${quote}`)}${quote}` : 
                        `${quote}${value}${quote}`;
                }
                return value;
            }).join(columnDelimiter);
            result += lineDelimiter;
        });
    
        return result;
    };
      
    const downloadCSV = (array, columns) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array, columns);
        if (csv == null) return;
    
        const filename = 'activitytransaction.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const getUser = JSON.parse(localStorage.getItem("auth"));
        if (getUser?.result) {
            setUserId(getUser.user.id);
            setToken(getUser.token);
        }
        setLoading(true);
        setTicketNumber(window.location.href.split("/")[5]);
    }, [ticketNumber]);
    
    useEffect(() => {
        const start = moment(date[0].startDate).format("YYYY-MM-DD");
        const end = moment(date[0].endDate).format("YYYY-MM-DD");
        setLoading(true);
        HTTP.get(`getEndActivityTransactions?start=${start}&end=${end}`, { headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } })
            .then((res) => {
                setActivityTransactions(res.data.activities)
                setTotalAmount(res.data.totalAmount);
                setLoading(false);
                console.log(res.data.activities)
            })
            .catch(setLoading(true));
    }, []);
  
    const customText = {
        rowsPerPageText: _("number_of_rows_per_page"),
        rangeSeparatorText: _("between")
    };

    const handleExport = () => {
        const formattedData = [];

        activityTransactions.forEach(activity => {
            activity.activityCartTickets.forEach(ticket => {
                ticket.invoice.forEach(inv => {
                    formattedData.push({
                        'activity_end_time': activity.end_time,
                        'activity_code': activity.number,
                        'name_surname': inv.fullname,
                        'tckn': inv.tckn,
                        'official_title_of_company': inv.firmname,
                        'tax_administration': inv.tax_office,
                        'tax_no': inv.tax_no,
                        'e_mail': inv.email,
                        'amount': ticket.transaction[0]?.formattedAmount,
                        'formatted_time': ticket.transaction[0]?.formattedTime,
                        'currency': 'TRY'
                    });
                });
            });
        });
        downloadCSV(formattedData);
    };

    const flattenData = (data) => {
        const flattened = [];
        data?.forEach(activity => {
            activity.activityCartTickets.forEach(ticket => {
                ticket.invoice.forEach(inv => {
                    flattened.push({
                        activity_end_time: activity.end_time,
                        activity_code: activity.number,
                        name_surname: inv.fullname,
                        tckn: inv.tckn,
                        official_title_of_company: inv.firmname,
                        tax_administration: inv.tax_office,
                        tax_no: inv.tax_no,
                        e_mail: inv.email,
                        formatted_time: ticket.transaction[0]?.formattedTime, 
                        amount: ticket.transaction[0]?.formattedAmount + " TRY" || "N/A"
                    });
                });
            });
        });
        return flattened;
    };
    const flattenedData = flattenData(activityTransactions);

    const columns = [
        {
            name: _('activity_end_time'),
            selector: row => row.activity_end_time,
            sortable: true,
            wrap: true,
            minWidth: '200px',
        },
        {
            name: _('activity_code'),
            selector: row => (
                <a className="homeText text-decoration-none" href={globals.url + 'a/' + row.activity_code} rel="noopener noreferrer">
                    {row.activity_code}
                </a>
            ),
            sortable: true,
            wrap: true,
            minWidth: '200px',
        },
        {
            name: _('formatted_time'),
            selector: row => row.formatted_time,
            sortable: true,
            wrap: true,
            minWidth: '200px',
        },
        {
            name: _('name_surname'),
            selector: row => row.name_surname,
            sortable: true,
            wrap: true,
            minWidth: '200px',
        },
        {
            name: _('tckn'),
            selector: row => row.tckn,
            sortable: true,
            wrap: true,
            minWidth: '200px'
        },
        {
            name: _('official_title_of_company'),
            selector: row => row.official_title_of_company,
            sortable: true,
            wrap: true,
            minWidth: '200px',
            sortFunction: (a, b) => {
                const nameA = a.official_title_of_company || ""; // Replace null or undefined with an empty string
                const nameB = b.official_title_of_company || "";
                return nameA.localeCompare(nameB);
            },
        },
        {
            name: _('tax_administration'),
            selector: row => row.tax_administration,
            sortable: true,
            minWidth: '150px',
            wrap: true,
            sortFunction: (a, b) => {
                const taxA = a.tax_administration || ""; // Replace null or undefined with an empty string
                const taxB = b.tax_administration || "";
                return taxA.localeCompare(taxB);
            },
        },
        {
            name: _('tax_no'),
            selector: row => row.tax_no,
            sortable: true,
            minWidth: '150px',
            wrap: true
        },
        {
            name: _('e_mail'),
            selector: row => row.e_mail,
            sortable: true,
            minWidth: '400px',
            wrap: true
        },
        {
            name: _('amount'),
            selector: row => row.amount,
            sortable: true,
            minWidth: '150px',
            wrap: true,
            sortFunction: (a, b) => {
                // Extract numeric part from the amount string and convert it to a number
                const amountA = parseFloat(a.amount.replace(" TRY", "")) || 0; // Fallback to 0 if conversion fails
                const amountB = parseFloat(b.amount.replace(" TRY", "")) || 0;
                return amountA - amountB; // Compare numerically
            },
        },
        
    ];
    
    return (
        <div>
          {loading ? <Loader/> :
                <>
                    <Navbar />
                    <div className="container mt-5">
                     
                        <div className="text-center mb-3 p-1" style={{fontWeight:'bold', fontSize: 18}}>{_("expired_activity_transactions")}</div>
                            <div className="justify-content-center mb-3">
                                <form
                                    className="row"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        const start = moment(date[0].startDate)
                                            .format("YYYY-MM-DD");
                                        const end = moment(date[0].endDate)
                                            .format("YYYY-MM-DD");
                                        setLoading(true);
                                            HTTP.get(`getEndActivityTransactions?start=${start}&end=${end}`, { headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } })
                                            .then((res) => {
                                                setActivityTransactions(res.data.activities)
                                                setTotalAmount(res.data.totalAmount);
                                                setLoading(false);
                                            
                                            })
                                            .catch(setLoading(true));
                                    }} >
                                    <div className="col-8 mb-2">
                                        <span
                                            onClick={() => setOpenDate(!openDate)}
                                            type="search"
                                            className="form-control bg-white headerInput">
                                            {`${format(date[0].startDate, "dd.MM.yyyy")} - ${format(date[0].endDate, "dd.MM.yyyy")}`}
                                        </span>
                                    </div>
                                    
                                        <div className="col-4">
                                            <button
                                                type="submit"
                                                className="btn btn-primary text-center btn1-color " >
                                                {/* <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="16"
                                                    height="16"
                                                    fill="currentColor"
                                                    className="bi bi-search"
                                                    viewBox="0 0 16 16" >
                                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"></path>
                                                </svg> */}
                                                <img className="bi bi-search" width="25" height="auto" style={{ fill: "currentcolor" }} src={images.explore_disable_icon} alt="search"></img>
                                            </button>
                                        </div>
                                        {openDate && (
                                            <>
                                                <div style={{ backgroundColor: '#EFF2F7', borderRadius: 4 }}>
                                                    <div className="d-flex justify-content-center">
                                                        <DateRange
                                                            editableDateInputs={true}
                                                            onChange={(item) => setDate([item.selection])}
                                                            moveRangeOnFirstSelection={false}
                                                            ranges={date}
                                                            className="date"
                                                            dateDisplayFormat="dd.MM.yyyy"
                                                            maxDate={new Date()}
                                                            minDate={new Date(2022, 12, 1)}
                                                            locale={
                                                                lang == "af" ? af :
                                                                lang == "ar" ? ar :
                                                                lang == "az" ? az :
                                                                lang == "bg" ? bg :
                                                                lang == "bs" ? bs :
                                                                lang == "cy" ? cy :
                                                                lang == "de" ? de :
                                                                lang == "el" ? el :
                                                                lang == "es" ? es :
                                                                lang == "fi" ? fi :
                                                                lang == "fr" ? fr :
                                                                lang == "he" ? he :
                                                                lang == "hi" ? hi :
                                                                lang == "hr" ? hr :
                                                                lang == "hu" ? hu :
                                                                lang == "it" ? it :
                                                                lang == "ja" ? ja :
                                                                lang == "kk" ? kk :
                                                                lang == "ko" ? ko :
                                                                lang == "lb" ? lb :
                                                                lang == "mk" ? mk :
                                                                lang == "ms" ? ms :
                                                                lang == "nl" ? nl :
                                                                lang == "nn" ? nn :
                                                                lang == "pt" ? pt :
                                                                lang == "ro" ? ro :
                                                                lang == "ru" ? ru :
                                                                lang == "sq" ? sq :
                                                                lang == "sr" ? sr :
                                                                lang == "sv" ? sv :
                                                                lang == "tk" ? tr :
                                                                lang == "tr" ? tr :
                                                                lang == "tw" ? zhTW :
                                                                lang == "uk" ? uk : enUS
                                                            }
                                                            rangeColors={["#D500F9"]}
                                                            color="#D500F9"
                                                        />
                                                    </div>
                                                    <div className="col text-center" onClick={e => setOpenDate(false)}>
                                                        <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            {_('ok')}
                                                        </a>
                                                    </div>
                                                </div>
                                            </>
                                        )}
                                </form>
                            </div>
                        
                    </div>
                    <div className="container">
                        <div className="d-flex justify-content-center card">
                            <div className="col-sm-12 col-md-4 mb-2">
                                <input
                                    type="search"
                                    className="form-control bg-white headerInput text-muted"
                                    placeholder={_("search")}
                                    aria-label={_("search")}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}>
                                </input>
                            </div>
                            <DataTable
                                columns={columns}
                                responsive
                                pagination
                                paginationPerPage={100}
                                paginationRowsPerPageOptions={[100, 500, 1000, 5000, 10000]}
                                paginationComponentOptions={customText}
                                noDataComponent={_('no_records')}
                                data={flattenedData.filter(item => {
                                    if (searchTerm === "") {
                                        return true;
                                    } else if (
                                        item.activity_end_time?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.activity_code?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.name_surname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.e_mail?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.tckn?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.official_title_of_company?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.tax_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.tax_administration?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.amount?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.formatted_time?.toLowerCase().includes(searchTerm.toLowerCase())
                                    ) {
                                        return true;
                                    }
                                    return false;
                                })}
                                actions={
                                    <button
                                        onClick={handleExport}
                                        className="bi bi-filetype-csv"
                                        style={{
                                            backgroundColor: '#933CAC',
                                            color: 'white',
                                            border: 'none',
                                            padding: '2px 5px',
                                            borderRadius: '4px'
                                        }}
                                    />
                                }
                            />
                         {totalAmount !== '0,00' &&
                                <div className="d-flex justify-content-end">
                                    <span style={{fontWeight:'bold'}}>{_('total_amount')}: {totalAmount} TRY</span>
                                </div>
                            }
                        </div>
                    </div>
                </>
          }
            <Footer />
        </div>
    );
};

export default ActivityTransactions;