import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import 'bootstrap/dist/css/bootstrap.min.css';
import { _ } from '../config/languages/i18n';
import images from "../images/images";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { useEffect } from "react";
import { Helmet } from "react-helmet";

const StoreWays = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Vidipass Page'
            },
            event: 'vidipass_page'
        })
    }, [])

    const cookies = new Cookies()
    var lang = cookies.get("language")

    useEffect(() => {
        var ua = navigator.userAgent.toLowerCase();
        var isAndroid = ua.indexOf("android") > -1;
        var isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
        var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
        if (isAndroid) {
            setTimeout(function () { window.location = "https://play.google.com/store/apps/details?id=com.vidipass"; }, 1000);
        }
        if (isIos) {
            setTimeout(function () { window.location = "https://apps.apple.com/tr/app/vidipass/id6476486667"; }, 1000);
        }
    }, [])

    return (
        <div>
            <Helmet>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mt-5">
                <div className="mt-5 text-center">
                    <h6 className="mt-5 mb-5 ">{_('vidipass_slogan')}</h6>
                </div>
                <div className="mt-5 text-center">
                    <img src={images.vidipass_vp_icon} className="w-25" style={{}} alt="..." />
                </div>
                <div className="mt-5 text-center">
                    <img src={images.vidipass_for_ticket} className="w-25" style={{}} alt="..." />
                </div>
                <div className="mt-5 text-center">
                    <a href="https://apps.apple.com/tr/app/vidipass/id6476486667" target="_blank">
                        <img src={images.app_store} className="w-25 mx-2" alt="..." />
                    </a>
                    <a href="https://play.google.com/store/apps/details?id=com.vidipass" cl target="_blank">
                        <img src={images.google_play} className="w-25 mx-2" alt="..." />
                    </a>
                    <img src={images.app_gallery} className="w-25 mx-2" alt="..." />
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default StoreWays