import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css';
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { useNavigate } from "react-router-dom";
import { Cookies } from "react-cookie";
import TagManager from 'react-gtm-module'
import { set } from "date-fns";
import globals from "../config/globals/globals";
import axios from "axios";
import moment from 'moment';
import ShowMoreText from "react-show-more-text";
import { Helmet } from "react-helmet";
import DataTable from 'react-data-table-component';

const ContactMessages = ({ setUser }) => {
    const navigate = useNavigate();
    const [contactsInfo, setContactsInfo] = useState([]);
    const [sloganNumber, setSloganNumber] = useState("");
    const cookies = new Cookies();
    const divRef = useRef(null);
    const HTTP = axios.create({ baseURL: globals.apiUrl });
    const [searchTerm, setSearchTerm] = useState('');

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    };

    useEffect(() => {
        TagManager.initialize(tagManagerArgs);
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Contact Messages Page'
            },
            event: 'contact_messages_page'
        });
    }, []);

    useEffect(() => {
        HTTP.get('getContact').then(res => {
            if (res.data.result) {
                setContactsInfo(res.data.contact);
            }
        });
    }, []);

    useEffect(() => {
        const lang = cookies.get("language");
        const sloganNum = (lang === 'tr') ? (Math.floor(Math.random() * 3) + 1) : (Math.floor(Math.random() * 3) + 4);
        setSloganNumber(sloganNum);
    }, []);

    useEffect(() => {
        if (divRef.current) {
            setTimeout(() => {
                window.scrollTo({
                    behavior: "smooth",
                    top: divRef.current.offsetTop
                });
            }, 200);
        }
    }, [divRef.current]);

    const columns = [
        {
            name: _('created_time'),
            selector: row => moment(row.created_at).format('YYYY-MM-DD HH:mm:ss'),
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('type'),
            selector: row => getTypeText(row.type), 
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('name_surname'),
            selector: row => row.namesurname,
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('phone'),
            selector: row => row.phone,
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('e_mail'),
            selector: row => row.email,
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('message'),
            selector: row => (
                <ShowMoreText
                lines={3}
                more={_("continue")}
                less={_("close")}
            >
                {row.extra}                
            </ShowMoreText> 
           
            ),
            sortable: true,
            minWidth: '150px',
            wrap: true
        },
    ];

    const getTypeText = (type) => {
        switch (type) {
            case 0: return _('contact');
            case 1: return _('artist');
            case 2: return _('manager');
            case 3: return _('organizer');
            case 4: return _('venue_owner');
            default: return '';
        }
    };
    return (
        <div>
            <Helmet>
                <title>Vidipass | {_("contact")}</title>
                <meta name="description" content={_("contact")} />
                <meta name="robots" content="noindex" />
                <link rel="alternate" href={window.location.href} hreflang="tr" />
                <link rel="canonical" href={window.location.href} hreflang="tr" />
            </Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
                <div className="d-flex flex-lg-row flex-column rounded-2 justify-content-center">
                    <div className="card card1 col">
                        <div className="row justify-content-center">
                            <div className="col-12 ">
                                <div className="card-body row">
                                    <h1 style={{ fontSize: 20 }} className="title">{_('contact')}</h1>
                                    <hr />
                                </div>
                                <div className="col-sm-12 col-md-4 mb-2 mx-2">
                                    <input
                                        type="search"
                                        className="form-control bg-white headerInput text-muted"
                                        placeholder={_("search")}
                                        aria-label={_("search")}
                                        value={searchTerm}
                                        onChange={(e) => setSearchTerm(e.target.value)}>
                                    </input>
                                </div>
                                <div className="card-body">
                                    <DataTable
                                        columns={columns}
                                        responsive
                                        pagination
                                        paginationPerPage={100}
                                        noDataComponent={_('no_records')}
                                        paginationRowsPerPageOptions={[100, 500, 1000, 5000, 10000]}
                                        expandOnRowClicked
                                        data={contactsInfo.filter((item) => {
                                            const typeText = getTypeText(item.type).toLowerCase();

                                            if (searchTerm === "") {
                                                return item;
                                            } else if (
                                                moment(item.created_at).format('YYYY-MM-DD HH:mm:ss').toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                typeText.includes(searchTerm.toLowerCase()) ||
                                                item.namesurname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                item.phone?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                item.email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                                item.extra?.toLowerCase().includes(searchTerm.toLowerCase())
                                            ) {
                                                return item;
                                            }
                                        })}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <span ref={divRef} />
            </div>
            <Footer />
        </div>
    );
};

export default ContactMessages;