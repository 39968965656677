import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import "../index.css";
import 'react-phone-number-input/style.css';
import { useState, useEffect } from "react";
import { _ } from "../config/languages/i18n";
import Loader from "../components/Loader";
import { useDispatch } from "react-redux";
import globals from "../config/globals/globals";
import "react-datepicker/dist/react-datepicker.css";
import { Cookies } from "react-cookie";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import moment from "moment";
import images from "../images/images";
import axios from "axios";
import { tr, de, enUS, af, ar, az, bg, bs, cy, el, es, fi, fr, he, hi, hr, hu, it, ja, kk, ko, lb, mk, ms, nl, nn, pt, ro, ru, sq, sr, sv, uk, zhTW } from "date-fns/locale";
import { format } from "date-fns";
import DataTable from 'react-data-table-component';

const WalletTransactions = () => {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [userId, setUserId] = useState("");
    const [token, setToken] = useState("");
    const timestamp = new Date();
    const cookies = new Cookies();
    const [openDate, setOpenDate] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [lang] = useState(cookies.get("language"));
    const [totalAmount, setTotalAmount] = useState("");
    const [walletTransactions, setWalletTransactions] = useState([])
    const [date, setDate] = useState([
        {
            startDate: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
            endDate: new Date(new Date()),
            key: "selection"
        }]);
    const [search, setSearch] = useState("");
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    const convertArrayOfObjectsToCSV = (array) => {
        if (array.length === 0) return null;
    
        const columnDelimiter = ',';
        const lineDelimiter = '\n';
        const quote = '"';
    
        const keys = Object.keys(array[0]);
        let result = '';
    
        // Add column headers
        result += keys.map(key => `${quote}${key}${quote}`).join(columnDelimiter);
        result += lineDelimiter;
    
        // Add rows
        array.forEach(item => {
            result += keys.map(key => {
                let value = item[key] !== undefined && item[key] !== null ? item[key] : '';
                if (typeof value === 'string') {
                    // Escape double quotes in the value and wrap it in double quotes
                    value = value.includes(quote) ? 
                        `${quote}${value.replace(new RegExp(quote, 'g'), `${quote}${quote}`)}${quote}` : 
                        `${quote}${value}${quote}`;
                }
                return value;
            }).join(columnDelimiter);
            result += lineDelimiter;
        });
    
        return result;
    };
      
    const downloadCSV = (array, columns) => {
        const link = document.createElement('a');
        let csv = convertArrayOfObjectsToCSV(array, columns);
        if (csv == null) return;
    
        const filename = 'wallettransactions.csv';
    
        if (!csv.match(/^data:text\/csv/i)) {
            csv = `data:text/csv;charset=utf-8,${csv}`;
        }
    
        link.setAttribute('href', encodeURI(csv));
        link.setAttribute('download', filename);
        document.body.appendChild(link); // Required for Firefox
        link.click();
        document.body.removeChild(link);
    };

    useEffect(() => {
        const getUser = JSON.parse(localStorage.getItem("auth"));
        if (getUser?.result) {
            setUserId(getUser.user.id);
            setToken(getUser.token);
        }
    }, []);
    
    useEffect(() => {
        const start = moment(date[0].startDate).format("YYYY-MM-DD");
        const end = moment(date[0].endDate).format("YYYY-MM-DD");
        setLoading(true);
        HTTP.get(`getWalletTransactions?start=${start}&end=${end}`, { headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } })
            .then((res) => {
                setWalletTransactions(res.data.walletTransactions)
                setTotalAmount(res.data.totalAmount);
                setLoading(false);
            })
            .catch(setLoading(true));
    }, []);

    const columns = [
        {
            name: _('date'),
            selector: row => row.formattedTime,
            sortable: true,
            minWidth: '200px',
            grow: 2,
            wrap: true
        },
        {
            name: _('name_surname'),
            selector: row => row.invoice[0].fullname,
            sortable: true,
            minWidth: '200px',
            wrap: true
        },
        {
            name: _('tckn'),
            selector: row => row.invoice[0].tckn,
            sortable: true,
            minWidth:'200px',
            wrap: true
        },
        {
            name: _('official_title_of_company'),
            selector: row => row.invoice[0].firmname,
            sortable: true,
            minWidth: '200px',
            wrap: true,
            sortFunction: (a, b) => {
                const nameA = a.invoice[0].firmname || ""; // Replace null or undefined with an empty string
                const nameB = b.invoice[0].firmname || "";
                return nameA.localeCompare(nameB);
            },
        },
        {
            name: _('tax_administration'),
            selector: row => row.invoice[0].tax_office,
            sortable: true,
            minWidth: '150px',
            wrap: true,
            sortFunction: (a, b) => {
                const taxA = a.invoice[0].tax_administration || ""; // Replace null or undefined with an empty string
                const taxB = b.invoice[0].tax_administration || "";
                return taxA.localeCompare(taxB);
            },
        },
        {
            name: _('tax_no'),
            selector: row => row.invoice[0].tax_no,
            sortable: true,
            minWidth: '150px',
            wrap: true
        },
        {
            name: _('e_mail'),
            selector: row => row.invoice[0].email,
            sortable: true,
            minWidth: '400px',
            wrap: true
        },
        {
            name: _('amount'),
            selector: row => row.formattedAmount + ' TRY',
            right: true,
            minWidth: '150px',
            wrap: true,
            sortFunction: (rowA, rowB) => {
                // Extract numeric part from the amount string and convert it to a number
                const amountA = parseFloat(rowA.formattedAmount.replace(/\s/g, '').replace(',', '.').replace(' TRY', '')) || 0;
                const amountB = parseFloat(rowB.formattedAmount.replace(/\s/g, '').replace(',', '.').replace(' TRY', '')) || 0;
                return amountA - amountB; // Compare numerically
            },
        },
    ];
    const customText = {
        rowsPerPageText: _("number_of_rows_per_page"),
        rangeSeparatorText: _("between")
    };

    const handleExport = () => {
        const formattedData = walletTransactions.map(item => ({
            'date': moment(item.formattedTime).format('YYYY-MM-DD'),
            'name_surname': String(item.invoice[0].fullname),
            'tckn': item.invoice[0]?.tckn,
            'official_title_of_company': item.invoice[0]?.firmname,
            'tax_administration': item.invoice[0]?.tax_office,
            'tax_no': item.invoice[0]?.tax_no,
            'e_mail': item.invoice[0]?.email,
            'amount':  parseFloat(item.formattedAmount.replace(/\s/g, '').replace(',', '.')),
            'currency': 'TRY'
        }));
    
        console.log("Formatted Data:", formattedData);
        downloadCSV(formattedData);
    };

    return (
        <div>
            {loading ? <Loader/> :
                <>
                    <Navbar />
                    <div className="container mt-5">
                        <div className="text-center mb-3 p-1" style={{fontWeight:'bold', fontSize: 18}}>{_("wallet_transactions")}</div>
                            <div className="justify-content-center mb-3">
                                <form
                                    className="row"
                                    onSubmit={(e) => {
                                        e.preventDefault();
                                        const start = moment(date[0].startDate)
                                            .format("YYYY-MM-DD");
                                        const end = moment(date[0].endDate)
                                            .format("YYYY-MM-DD");
                                        setLoading(true);
                            
                                        HTTP.get(`getWalletTransactions?start=${start}&end=${end}`, { headers: { Authorization: `bearer ${JSON.parse(localStorage.getItem("auth")).token}` } })
                                            .then((res) => {                                                
                                                setWalletTransactions(res.data.walletTransactions)
                                                setTotalAmount(res.data.totalAmount);
                                                setLoading(false);
                                            })
                                            .catch((error) => {
                                                setLoading(false);
                                            });
                                    }}
                                >
                                    <div className="col-8 mb-2">
                                    <span
                                            onClick={() => setOpenDate(!openDate)}
                                            type="search"
                                            className="form-control bg-white headerInput">
                                            {`${format(date[0].startDate, "dd.MM.yyyy")} - ${format(date[0].endDate, "dd.MM.yyyy")}`}
                                        </span>
                                    </div>
                                    <div className="col-4">
                                            <button
                                                onClick={() => ('search')}
                                                type="submit"
                                                className="btn btn-primary text-center btn1-color"
                                            >
                                                <img
                                                    className="bi bi-search"
                                                    width="25"
                                                    height="auto"
                                                    style={{ fill: "currentColor" }}
                                                    src={images.explore_disable_icon}
                                                    alt="search"
                                                />
                                            </button>
                                        </div>

                                    {openDate && (
                                            <div style={{ backgroundColor: '#EFF2F7', borderRadius: 4 }}>
                                                <div className="d-flex justify-content-center">
                                                    <DateRange
                                                        editableDateInputs={true}
                                                        onChange={(item) => setDate([item.selection])}
                                                        moveRangeOnFirstSelection={false}
                                                        ranges={date}
                                                        className="date"
                                                        dateDisplayFormat="dd.MM.yyyy"
                                                        minDate={new Date(2022, 12, 1)}
                                                        locale={
                                                            lang == "af" ? af :
                                                            lang == "ar" ? ar :
                                                            lang == "az" ? az :
                                                            lang == "bg" ? bg :
                                                            lang == "bs" ? bs :
                                                            lang == "cy" ? cy :
                                                            lang == "de" ? de :
                                                            lang == "el" ? el :
                                                            lang == "es" ? es :
                                                            lang == "fi" ? fi :
                                                            lang == "fr" ? fr :
                                                            lang == "he" ? he :
                                                            lang == "hi" ? hi :
                                                            lang == "hr" ? hr :
                                                            lang == "hu" ? hu :
                                                            lang == "it" ? it :
                                                            lang == "ja" ? ja :
                                                            lang == "kk" ? kk :
                                                            lang == "ko" ? ko :
                                                            lang == "lb" ? lb :
                                                            lang == "mk" ? mk :
                                                            lang == "ms" ? ms :
                                                            lang == "nl" ? nl :
                                                            lang == "nn" ? nn :
                                                            lang == "pt" ? pt :
                                                            lang == "ro" ? ro :
                                                            lang == "ru" ? ru :
                                                            lang == "sq" ? sq :
                                                            lang == "sr" ? sr :
                                                            lang == "sv" ? sv :
                                                            lang == "tk" ? tr :
                                                            lang == "tr" ? tr :
                                                            lang == "tw" ? zhTW :
                                                            lang == "uk" ? uk : enUS
                                                        }
                                                        rangeColors={["#D500F9"]}
                                                        color="#D500F9"
                                                    />
                                                </div>
                                                <div className="col text-center" onClick={() => setOpenDate(false)}>
                                                    <a className="text-decoration-none btn btn-primary saloonButton rounded-2">
                                                            {_('ok')}
                                                    </a>
                                                </div>
                                            </div>
                                        )}
                                </form>
                            </div>
                    </div>
                    <div className="container">
                        <div className="d-flex justify-content-center card">
                            <div className="col-sm-12 col-md-4 mb-2">
                                <input
                                    type="search"
                                    className="form-control bg-white headerInput text-muted"
                                    placeholder={_("search")}
                                    aria-label={_("search")}
                                    value={searchTerm}
                                    onChange={(e) => setSearchTerm(e.target.value)}>
                                </input>
                            </div>
                            <DataTable
                                columns={columns}
                                responsive
                                pagination
                                paginationPerPage={100}
                                paginationRowsPerPageOptions={[100,500,1000,5000,10000]}
                                paginationComponentOptions={customText}
                                noDataComponent= {_('no_records')}
                                data={walletTransactions.filter((item) => {
                                    if (searchTerm === "") {
                                        return item;
                                    } else if (
                                        item.invoice[0].fullname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.invoice[0].email?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.invoice[0].tckn?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.invoice[0].firmname?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.invoice[0].tax_no?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.invoice[0].tax_office?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.invoice[0].formattedAmount?.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                        item.invoice[0].formattedTime?.toLowerCase().includes(searchTerm.toLowerCase())
                                    ) {
                                        return item;
                                    }
                                })}
                                actions={
                                    <button
                                        onClick={handleExport}
                                        className="bi bi-filetype-csv"
                                        style={{
                                            backgroundColor: '#933CAC',
                                            color: 'white',
                                            border: 'none',
                                            padding: '2px 5px',
                                            borderRadius: '4px'
                                        }}
                                    />
                                }
                            />
                            {totalAmount !== '0,00' &&
                                <div className="d-flex justify-content-end">
                                    <span style={{fontWeight:'bold'}}>{_('total_amount')}: {totalAmount} TRY</span>
                                </div>
                            }
                        </div>
                    </div>
                </>
            }
            <Footer />
        </div>
    );
};

export default WalletTransactions;