import React, {useState} from 'react'; 
import TagManager from 'react-gtm-module';


const ScrollButton = () =>{ 

const [visible, setVisible] = useState(false) 

const toggleVisible = () => { 
	const scrolled = document.documentElement.scrollTop; 
	if (scrolled > 300){ 
	setVisible(true) 
	} 
	else if (scrolled <= 300){ 
	setVisible(false) 
	} 
}; 

const scrollToTop = () =>{ 
	window.scrollTo({ 
	top: 0, 
	behavior: 'smooth'

	}); 
}; 

window.addEventListener('scroll', toggleVisible); 

const tagManagerArgs = {
	gtmId: 'GTM-PQCRDTTD',
}

const gtmTag = (val) =>{
	TagManager.initialize(tagManagerArgs)
	window.dataLayer.push({
		event: val
	})
}

return ( 
	<div onClick={()=>gtmTag('scroll_button')} className='position-sticky d-inline-block' style={{bottom: '60px', fontSize:'30px', left:'99999999px', marginRight:20}}> 
	    <span className="bi bi-arrow-up-circle-fill" onClick={scrollToTop} style={{display: visible ? 'inline' : 'none', color:'#933CAC'}}></span>  
	</div> 
); 
} 

export default ScrollButton; 
