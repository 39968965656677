import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import { useState, useEffect, useRef } from "react";
import { _ } from "../config/languages/i18n";
import { Cookies } from "react-cookie";
import Loader from "../components/Loader";
import TagManager from 'react-gtm-module'
import { useDispatch } from "react-redux";
import { saveContact } from "../redux/actions/auth";
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import globals from "../config/globals/globals";
import { Helmet } from "react-helmet";
import axios from "axios";

const Contact = () => {
    const dispatch = useDispatch();
    const [nameSurname, setNameSurname] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [type, setType] = useState(0);
    const [country_code, setCountry] = useState("TR");
    const [sloganNumber, setSloganNumber] = useState("")
    const cookies = new Cookies()
    const divRef = useRef(null)
    const [loading, setLoading] = useState(false);
    const [verify, setVerify] = useState(false);
    const [contactButton, setContactButton] = useState(true);
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    function emailControl(data) {
        var regex = /^[a-zA-Z0-9._-]+@([a-zA-Z0-9.-]+.)+([.])+[a-zA-Z0-9.-]{2,4}$/;
        if (regex.test(data) == true) {
            return true;
        } else {
            return false;
        }
    }

    function nameControl(data) {
        var regex = /^[a-zA-Z\xC0-\uFFFF]{2,40}( [a-zA-Z\xC0-\uFFFF]{2,40})+$/;
        if (regex.test(data) == true) {
            return true;
        } else {
            return false;
        }
    }

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtc(new Date().getTimezoneOffset()/-60)
	},[])

	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

    useEffect(() => {
        setLoading(true);
        const lang = cookies.get("language")
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
            setLoading(false)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
            setLoading(false)
        }
    }, [sloganNumber]);

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'Contact Page'
            },
            event: 'contact_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    const onChange = (value) => {
        setVerify(true);
    }

    return (
        <div >
            <Helmet>
                <title>Vidipass | {_("contact")}</title>
                <meta name="description" content={_("meta_content_04")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            {loading && <Loader/>}
                <>
                    <Navbar />
                    <div className="container mb-5 mt-5">
                        <div className="d-flex flex-lg-row flex-column rounded-2">
                            <div className="card card1 order-2 me-1 mb-1">
                                <div className="row justify-content-center my-auto">
                                    <div className="col-md-8 col-10">
                                        <div className="row justify-content-center mb-3 mt-3">
                                            <img width={25} height={'auto'} src={images.vidipass_logo} className="w-50" alt="vidipass logo" />
                                        </div>
                                        <div className="form-group">
                                            <h1 style={{fontSize:16}} className="panel-title homeText">{_("contact")}</h1>
                                        </div>
                                        <form onSubmit={(e) => {
                                            setContactButton(false)
                                            e.preventDefault();
                                            // setNameSurname("");
                                            // setPhone("");
                                            // setEmail("");
                                            // setMessage("");
                                            if (nameControl(nameSurname) == false) {
                                                setTimeout(() => setContactButton(true), 4000);
                                                toast(_('invalid_name'), {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                    type: "error"
                                                })
                                                return;
                                            }
                                            else if (emailControl(email) == false) {
                                                setTimeout(() => setContactButton(true), 4000);
                                                toast(_('invalid_email'), {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                    type: "error"
                                                })
                                                return;
                                            }
                                            else if (phone.length != 13) {
                                                setTimeout(() => setContactButton(true), 4000);
                                                toast(_('invalid_phone'), {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                    type: "error"
                                                })
                                                return;
                                            }
                                            else if (message.length < 9 || message.length > 599) {
                                                setTimeout(() => setContactButton(true), 4000);
                                                toast(_('invalid_message'), {
                                                    position: "top-right",
                                                    autoClose: 3000,
                                                    hideProgressBar: false,
                                                    closeOnClick: false,
                                                    pauseOnHover: false,
                                                    draggable: false,
                                                    progress: undefined,
                                                    theme: "light",
                                                    closeButton: false,
                                                    type: "error"
                                                })
                                                return;
                                            }
                                            else {
                                                const formData = new FormData();
                                                formData.append("type", type);
                                                formData.append("namesurname", nameSurname);
                                                formData.append("email", email);
                                                formData.append("phone", phone);
                                                formData.append("phone_number", phone);
                                                formData.append("country_code", country_code);
                                                formData.append("extra", message);
                                                formData.append("ip_address", ip);
                                                formData.append("device_name", device);
                                                formData.append("utc", utc);
                                                dispatch(saveContact(formData));
                                            }
                                        }} >
                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("name_surname")}</label>
                                                <input type="text" onChange={(e) => setNameSurname(e.target.value)} placeholder={_("name_surname")} className="form-control" />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("phone")}</label>
                                                <PhoneInput
                                                    placeholder={_("phone")}
                                                    value={phone}
                                                    onChange={setPhone}
                                                    className="headerInput"
                                                    defaultCountry="TR"
                                                    onCountryChange={setCountry}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label className="form-control-label text-muted">{_("e_mail")}</label>
                                                <input type="text" onChange={(e) => setEmail(e.target.value)} placeholder={_("e_mail")} className="form-control" />
                                            </div>

                                            <div className="form-group mb-2">
                                                <label className="form-control-label text-muted">{_("message")}</label>
                                                <textarea rows="2" cols="50" onChange={(e) => setMessage(e.target.value)} placeholder={_("message")}></textarea>
                                            </div>
                                            <div className="form-check mb-2">
                                                <label className="form-check-label mt-2" htmlFor="exampleCheck1">
                                                    {/* <input  type="checkbox" className="form-check-input" id="exampleCheck1" checked={term} onChange={onChangeTerm} /> */}
                                                    {_("terms_of_use_01")} <a href={globals.url + "i/legal"} target="_blank" style={{ color: "#933CAC", textDecoration: "none" }}>{_("terms_of_use_02")}</a>{_("terms_of_use_03")}
                                                </label>
                                            </div>
                                            <ReCAPTCHA
                                                sitekey="6LfwQKwmAAAAABGb8zXTCPFFzB3MxWqDCPVd_-Lr"
                                                onChange={onChange}
                                                className="d-flex justify-content-center"
                                            />

                                            <div onClick={()=>gtmTag('send_button_contact')} className="row justify-content-center my-3 px-3">
                                                <button disabled={!(verify && nameSurname && phone && email && message && contactButton)} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("send")}</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="card card2 order-1 me-1 mb-1">
                                {
                                    sloganNumber == 1 ? <img src={images.slogan_1} width='100%' height='auto' className="rounded-1" alt="slogan 1" /> :
                                    sloganNumber == 2 ? <img src={images.slogan_2} width='100%' height='auto' className="rounded-1" alt="slogan 2" /> :
                                    sloganNumber == 3 ? <img src={images.slogan_3} width='100%' height='auto' className="rounded-1" alt="slogan 3" /> :
                                    sloganNumber == 4 ? <img src={images.slogan_4} width='100%' height='auto' className="rounded-1" alt="slogan 4" /> :
                                    sloganNumber == 5 ? <img src={images.slogan_5} width='100%' height='auto' className="rounded-1" alt="slogan 5" /> :
                                    <img src={images.slogan_6} width='100%' height='auto' className="rounded-1" alt="slogan 6" />
                                }
                            </div>
                        </div>
                    </div>
                    <span ref={divRef} />
                </>
            <Footer />
        </div>
    )
}

export default Contact