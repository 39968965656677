import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import 'react-phone-number-input/style.css'
import { useState, useEffect, useRef } from "react";
import { _ } from '../config/languages/i18n';
import { NewPasswordAction } from "../redux/actions/auth";
import { codeSendAgainPasswordAction } from "../redux/actions/auth";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Cookies } from "react-cookie";
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import TagManager from 'react-gtm-module'
import { Helmet } from "react-helmet";
import globals from '../config/globals/globals';
import axios from "axios";

const NewPassword = () => {
    const [password, setPassword] = useState("");
    const [password_confirmation, setPasswordAgain] = useState("");
    const [code, setCode] = useState("");
    const [codeStart, setCodeStart] = useState("");
    const [codeEnd, setCodeEnd] = useState("");
    const dispatch = useDispatch();
    const [user, setUser] = useState("");
    const formData = new FormData();
    const [sloganNumber, setSloganNumber] = useState("")
    const [counter, setCounter] = useState("");
    const cookies = new Cookies()
    const divRef = useRef(null)
    const lang = cookies.get("language")
    const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utc, setUtc] = useState('')
    const HTTP = axios.create({ baseURL: globals.apiUrl });

    useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtc(new Date().getTimezoneOffset()/-60)
	},[])

	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

    useEffect(() => {
        if (lang == 'tr') {
            const sloganNumber = (Math.floor(Math.random() * 3) + 1);
            setSloganNumber(sloganNumber)
        } else {
            const sloganNumber = (Math.floor(Math.random() * 3) + 4);
            setSloganNumber(sloganNumber)
        }
    }, []);

    useEffect(() => {
        const getuser = JSON.parse(localStorage.getItem('auth'));
        if (getuser) {
            setUser(getuser.user);
            if (getuser.user.usabilitySeconds) {
                setCounter(getuser.user.usabilitySeconds);
            } else {
                setCounter(120);
            }
        }
    }, []);

    useEffect(() => {
        counter > 0 && setTimeout(() => setCounter(counter - 1), 1000);
    }, [counter]);
    const [newPasswordShown, setNewPasswordShown] = useState("password");
    const toggleNew = () => {
        if (newPasswordShown === "password") {
            setNewPasswordShown("text");
            return;
        }
        setNewPasswordShown("password");
    };

    const [againPasswordShown, setAgainPasswordShown] = useState("password");
    const toggleAgain = () => {
        if (againPasswordShown === "password") {
            setAgainPasswordShown("text");
            return;
        }
        setAgainPasswordShown("password");
    };

    useEffect(() => {
        setTimeout(() => {
            window.scrollTo({
                behavior: "smooth",
                top: divRef.current.offsetTop
            })
        }, 200)
    }, [divRef.current])

    const handleCodeStart = (evt) => {
        if (evt.length < 3) {
            return setCodeStart(evt)
        }
    };
    const handleCodeEnd = (evt) => {
        if (evt.length < 3) {
            return setCodeEnd(evt)
        }
    };

    const tagManagerArgs = {
        gtmId: 'GTM-PQCRDTTD',
    }

    useEffect(() => {
        TagManager.initialize(tagManagerArgs)
        window.dataLayer = [];
        window.dataLayer.push({
            page: {
                url: window.location.href,
                title: 'New Password Page'
            },
            event: 'new_password_page'
        })
    }, [])

    const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

    return (
        <div >
            <Helmet>
                <title>Vidipass | {_("new_password")}</title>
                <meta name="description" content={_("new_password")}/>
                <meta name="robots" content="noindex"/>
                <link rel="alternate" href={window.location.href} hreflang="tr"/>
                <link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
            <Navbar />
            <div className="container mb-5 mt-5">
                <div className="card col-md-6 offset-md-3">
                    <div className="row justify-content-center my-auto">
                        <div className="col-md-8 col-10">
                            {
                                user.email ?
                                    <div className="row justify-content-center mb-3 mt-3">

                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("enter_activation_code_for_email")}</p>
                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >({user.email})</p>
                                    </div>
                                    :
                                    <div className="row justify-content-center mb-3 mt-3">

                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("enter_activation_code_for_phone")}</p>
                                        <p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >({user.phone})</p>
                                    </div>
                            }
                            <div className="row justify-content-center mb-3">
                                <img width={25} height={'auto'} className="w-25 mb-2" src={images.forgot_password_icon} alt="forgot password" />
                                <h1 className="d-flex justify-content-center" style={{ color: "#933CAC", fontSize:16 }} type="submit">{_("forgot_password")}</h1>
                            </div>
                            {
                                counter > 0 ?
                                    <form onSubmit={(e) => {
                                        e.preventDefault();
                                        const email = user.email;
                                        const phone = user.phone;
                                        const type = user.type;
                                        if (codeStart.length !== 2 || codeEnd.length !== 2) {
                                            toast(_('password_must_be_four_characters'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: "error"
                                            })
                                        }
                                        else if (password.length < 8) {
                                            toast(_('password_must_be_at_least_eight_characters'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: "error"
                                            })
                                        }
                                        else if (password_confirmation.length < 8) {
                                            toast(_('password_must_be_at_least_eight_characters'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: "error"
                                            })
                                        }
                                        else if (!(password == password_confirmation)) {
                                            toast(_('password_and_password_confirmation_must_be_same'), {
                                                position: "top-right",
                                                autoClose: 3000,
                                                hideProgressBar: false,
                                                closeOnClick: false,
                                                pauseOnHover: false,
                                                draggable: false,
                                                progress: undefined,
                                                theme: "light",
                                                closeButton: false,
                                                type: "error"
                                            })
                                        }

                                        else {
                                            setPassword("");
                                            setPasswordAgain("");
                                            setCode("");
                                            formData.append("password", password);
                                            formData.append("password_confirmation", password_confirmation);
                                            formData.append("code", codeStart + codeEnd);
                                            formData.append("email", email);
                                            formData.append("phone", phone);
                                            formData.append("type", phone ? 2 : 1);
                                            formData.append("lang", lang);
                                            formData.append("ip_address", ip);
                                            formData.append("device_name", device);
                                            formData.append("utc", utc);
                                            dispatch(NewPasswordAction(formData));
                                        }

                                    }}>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("activation_code")}</label>
                                            <div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white">
                                                <span type="text" className="me-1" autofocus="">v</span>
                                                <span type="text" className="me-1" autofocus="">-</span>
                                                <input value={codeStart} onChange={(e) => handleCodeStart(e.target.value)} style={{ width: 50 }} type="text" className="form-control me-1" />
                                                <span type="text" className="me-1" autofocus="">-</span>
                                                <input value={codeEnd} onChange={(e) => handleCodeEnd(e.target.value)} style={{ width: 50 }} type="text" className="form-control" />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("new_password")}</label>
                                            <div className="input-group">
                                                <input type={newPasswordShown} value={password} onChange={(e) => setPassword(e.target.value)} placeholder={_("new_password")} className="form-control" />
                                                <i className=" btn btn-secondary text-center btn2-color" onClick={toggleNew}>
                                                    {newPasswordShown === "password" ? (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-slash-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    )}
                                                </i>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="form-control-label text-muted">{_("new_password_again")}</label>
                                            <div className="input-group">
                                                <input type={againPasswordShown} value={password_confirmation} onChange={(e) => setPasswordAgain(e.target.value)} placeholder={_("new_password_again")} className="form-control" />
                                                <i className=" btn btn-secondary text-center btn2-color" onClick={toggleAgain}>
                                                    {againPasswordShown === "password" ? (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-slash-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                                            <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                                        </svg>
                                                    ) : (
                                                        <svg
                                                            width="20"
                                                            height="17"
                                                            fill="currentColor"
                                                            className="bi bi-eye-fill"
                                                            viewBox="0 0 16 16"
                                                        >
                                                            <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                                            <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                                        </svg>
                                                    )}
                                                </i>
                                            </div>
                                        </div>
                                        <div onClick={()=>gtmTag('ok_button_new_password')} className="row justify-content-center my-3 px-3">
                                            <button disabled={!((codeStart && codeEnd) && password && password_confirmation)} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("ok")}</button>
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            <CountdownCircleTimer
                                                isPlaying
                                                duration={120}
                                                colors={['#2E81D2', '#933CAC', '#D31095', '#EC008C']}
                                                size={100}
                                                colorsTime={[120, 80, 40, 0]}
                                            >
                                                {({ remainingTime }) => remainingTime}
                                            </CountdownCircleTimer>
                                        </div>
                                    </form>
                                    :
                                    <form onSubmit={(e) => {
                                        e.preventDefault()
                                        const email = user.email;
                                        const phone = user.phone;
                                        const phone_number = user.phone_number;
                                        const country_code = user.country_code;
                                        const type = user.type;
                                        const password_reset_or_register_type = 2;
                                        formData.append("email", email);
                                        formData.append("phone", phone);
                                        formData.append("phone_number", phone_number);
                                        formData.append("country_code", country_code);
                                        formData.append("type", phone ? 2 : 1);
                                        formData.append("password_reset_or_register_type", password_reset_or_register_type);
                                        formData.append("lang", lang);
                                        formData.append("ip_address", ip);
                                        formData.append("device_name", device);
                                        formData.append("utc", utc);
                                        dispatch(codeSendAgainPasswordAction(formData));
                                    }}>
                                        <div onClick={()=>gtmTag('resend_code_button_new_password')} className="row justify-content-center my-3 px-3">
                                            <button className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" type="submit">{_("resend_code")}</button>
                                        </div>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
                {/* <div className="card card2 order-1 me-1 mb-1">
                        {sloganNumber == 1 ? <img src={images.slogan_1} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 2 ? <img src={images.slogan_2} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 3 ? <img src={images.slogan_3} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 4 ? <img src={images.slogan_4} className="w-100 rounded-1" alt="..." /> :
                            sloganNumber == 5 ? <img src={images.slogan_5} className="w-100 rounded-1" alt="..." /> :
                            <img src={images.slogan_6} className="w-100 rounded-1" alt="..." /> 
                        }
                    </div> */}

            </div>
            <span ref={divRef} />
            <Footer />
        </div>
    )
}

export default NewPassword