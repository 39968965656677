const month = [
    {
        "key": "1",
        "value": "01"
    },
    {
        "key": "2",
        "value": "02"
    },
    {
        "key": "3",
        "value": "03"
    },
    {
        "key": "4",
        "value": "04"
    },
    {
        "key": "5",
        "value": "05"
    },
    {
        "key": "6",
        "value": "06"
    },
    {
        "key": "7",
        "value": "07"
    },
    {
        "key": "8",
        "value": "08"
    },
    {
        "key": "9",
        "value": "09"
    },
    {
        "key": "10",
        "value": "10"
    },
    {
        "key": "11",
        "value": "11"
    },
    {
        "key": "12",
        "value": "12"
    },
];

export function getMonth() {
    return month;
};