import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import ".././index.css";
import images from "../images/images";
import "react-phone-number-input/style.css";
import { useState, useEffect, useRef, useCallback } from "react";
import globals from "../config/globals/globals";
import { _ } from "../config/languages/i18n";
import moment from "moment";
import axios from "axios";
import Loader from "../components/Loader";
import Zoom from "react-medium-image-zoom";
import "react-medium-image-zoom/dist/styles.css";
import Modal from 'react-bootstrap/Modal';
import { CountdownCircleTimer } from 'react-countdown-circle-timer'
import ShowMoreText from "react-show-more-text";
import WhatsAppWidget from "react-whatsapp-chat-widget";
import "react-whatsapp-chat-widget/index.css";
import Linkify from 'linkify-react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";
import TagManager from 'react-gtm-module'
import Multiselect from 'multiselect-react-dropdown';

const Ticketing = () => {
	const timestamp = new Date();
	const formData = new FormData();
	const [loading, setLoading] = useState(false);
	const [activityName, setActivityName] = useState("");
	const [activityCategoryName, setActivityCategoryName] = useState("");
	const [startDate, setStartDate] = useState("");
	const [startTime, setStartTime] = useState("");
	const [startDateTime, setStartDateTime] = useState("");
	const [endDate, setEndDate] = useState("");
	const [endTime, setEndTime] = useState("");
	const [endDateTime, setEndDateTime] = useState("");
	const [description, setDescription] = useState("");
	const [number, setNumber] = useState();
	const [category, setCategory] = useState("null");
	const [seatCategory, setSeatCategory] = useState("null");
	const [activityId, setActivityId] = useState("");
	const [activityPhoto, setActivityPhoto] = useState("");
	const [token, setToken] = useState("");
	const [hall, setHall] = useState("");
	const [seat, setSeat] = useState("");
	const [seatName, setSeatName] = useState("");
	const [groupId, setGroupId] = useState("");
	const [seatId, setSeatId] = useState("");
	const [price, setPrice] = useState("");
	const [ticket, setTicket] = useState("");
	const [priceCode, setPriceCode] = useState("");
	const [utc, setUTC] = useState("")
	const [counter, setCounter] = useState("");
	const HTTP = axios.create({ baseURL: globals.apiUrl });
	const [showSaloonModal, setShowSaloonModal] = useState(false);
	const [reportModal, setReportModal] = useState(false);
	const [deleteCartModal, setDeleteCartModal] = useState(false);
	const [deleteTicketModal, setDeleteTicketModal] = useState(false);
	const [deleteTicketId, setDeleteTicketId] = useState(false)
	const [imgCreated, setImgCreated] = useState("")
	const [planURL, setPlanURL] = useState("")
	const [extra, setExtra] = useState("")
	const [password, setPassword] = useState("")
	const [place, setPlace] = useState("")
	const [ticketNameSurname, setTicketNameSurname] = useState("null");
	const divRef = useRef(null)
	const [term, setTerm] = useState(false)
	const [emailTerm, setEmailTerm] = useState(false)
	const [insurance, setInsurance] = useState(2)
	const [cancelInsuranceModal, setCancelInsuranceModal] = useState(false)
	const [priceStatus, setPriceStatus] = useState("")
	const [ticketNumber, setTicketNumber] = useState(0)
	const [serverTime, setServerTime] = useState("")
	const [urlModal, setUrlModal] = useState(false)
	const [copy, setCopy] = useState(false)
	const [startDateDay, setStartDateDay] = useState("")
	const [endDateDay, setEndDateDay] = useState("")
	const [mySeats, setMySeats] = useState([])
	const [activityStatus, setActivityStatus] = useState("")
	const [addStatus, setAddStatus] = useState(0)
	const [cityName, setCityName] = useState("")
	const [activatedMail, setActivatedMail] = useState('')
	const [extraMail, setExtraMail] = useState('')
	const [activatedModal, setActivatedModal] = useState(false)
	const [codeStart, setCodeStart] = useState("");
	const [codeEnd, setCodeEnd] = useState("");
	const [metaDescription, setMetaDescripton]= useState("")
    const [metaKeywords, setMetaKeywords] = useState([])
	const [ip, setIp] = useState('')
    const [device, setDevice] = useState('')
    const [utcLog, setUtcLog] = useState('')

	useEffect(()=>{
		setDevice(navigator.userAgent)
	},[])

    useEffect(()=>{
		setUtcLog(new Date().getTimezoneOffset()/-60)
	},[])

	useEffect(()=>{
		HTTP.get('https://api.ipify.org?format=json')
		.then(
			res=>{
				setIp(res.data.ip)
			}
		)
	},[])

	useEffect(()=>{
		if(seat.length>0 && seatCategory !== "null" && category !== "null" && priceStatus==1){
			setTicketNumber(1)
		}else{
			setTicketNumber(0)
		}

	},[seat,seatCategory,mySeats,category])
	
	useEffect(() => {
		const getuser = JSON.parse(localStorage.getItem("auth"));
		if (getuser.result) {
			setToken(getuser.token);
			if(getuser.user.activated_email_extra){
				setActivatedMail(getuser.user.email_extra)
			}else{
				setActivatedMail('')
			}
			
		}
		setLoading(true);
		setNumber(window.location.href.split("/")[5]);
		if (number) {
			HTTP.get(`/activities/get/${number}`, { headers: { Authorization: `bearer ${getuser.token}` }, })
				.then((res) => {
					if (res.data.result) {
						setCityName(res.data.activity.city.name)
						setActivityName(res.data.activity.title);
						setStartDate(moment(res.data.activity.start_time).format("DD.MM.YYYY"));
						setStartTime(moment(res.data.activity.start_time).format("HH:mm"));
						setStartDateTime(res.data.activity.start_time);
						setEndDate(moment(res.data.activity.end_time).format("DD.MM.YYYY"));
						setEndTime(moment(res.data.activity.end_time).format("HH:mm"));
						setEndDateTime(res.data.activity.end_time);
						setDescription(res.data.activity.description);
						setMetaDescripton(res?.data?.activity?.meta_description)
						if (res.data.activity.meta_keywords !== null && res.data.activity.meta_keywords !== '') {
                            setMetaKeywords(JSON.parse(res.data.activity.meta_keywords));
                          }
						setActivityCategoryName(res.data.activity.category.name);
						setActivityId(res.data.activity.id);
						setActivityPhoto(res.data.activity.photo_name);
						setPriceCode(res.data.activity.currency.tcmb_code)
						setToken();
						setHall(res.data.activity.hall);
						setUTC(res.data.activity.utc);
						setImgCreated(res.data.activity.created_at)
						setPlanURL(res.data.activity.hall.plan_file_url)
						setExtra(res.data.activity.extra)
						setPlace(res.data.activity.place.title)
						setTicketNameSurname(getuser.user.username)
						setServerTime(res.data.time)
						setStartDateDay(moment(res.data.activity.start_time).format('dddd').toLocaleLowerCase())
						setEndDateDay(moment(res.data.activity.end_time).format('dddd').toLocaleLowerCase())
						setActivityStatus(res.data.activity.activity_status)
						setLoading(false);
						setTimeout(() => {
							window.scrollTo({
								behavior: "smooth",
								top: divRef.current.offsetTop
							})
						}, 200)
					} else {
						setLoading(true);
						window.location.href = globals.url;
					}
				})
				.catch(setLoading(true));
		}
	}, [number]);

	useEffect(() => {
		const getuser = JSON.parse(localStorage.getItem("auth"));
		if (getuser.result) {
			setToken(getuser.token);
		}
		setLoading(true);
		HTTP.get("/getCart", { headers: { Authorization: `bearer ${getuser.token}` } })
			.then((res) => {
				if (res.data.cart) {
					setTicket(res.data.cart.tickets)
					setCounter(res.data.cart.cartTime)
					localStorage.setItem('basketsNumber', JSON.stringify(res.data.cart.tickets.length))
				} else {
					setLoading(false);
				}
			})
			.catch(setLoading(true));
	}, []);

	useEffect(() => {
		ticket ? setTimeout(() => setCounter(counter - 1), 1000) : setCounter("")
	}, [counter]);

	const frontRemoveTicket = (e) => {
		setDeleteTicketId(e)
		setDeleteTicketModal(true)
	}

	const onChangeTerm = (value) => {
		//setTerm(!term);
		if (!term) {
			setTerm(!term);
			setInsurance(1)
		} else {
			//setInsurance(0)
			// modal ac
			setCancelInsuranceModal(true)
		}
	}

	const onChangeEmailTerm = (value) => {
		setEmailTerm(!emailTerm);
	}

	const handleCodeStart = (evt) => {
		if (evt.length < 3) {
			return setCodeStart(evt)
		}
	};

	const handleCodeEnd = (evt) => {
		if (evt.length < 3 && codeStart.length == 2) {
			return setCodeEnd(evt)
		}
	};

	useEffect(() => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
	}, [divRef.current])

	const removeTicket = (e) => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
		const ticket_id = e
		const getuser = JSON.parse(localStorage.getItem("auth"));
		if (deleteTicketId) {
			HTTP.post("/deleteTicket", { ticket_id, device, utcLog, ip }, { headers: { Authorization: `bearer ${getuser.token}` } })
				.then((res) => {
					if (res.data.result) {
						// setTicket(res.data.cart?.tickets)
						setSeatCategory("null")
						getTicket()
						setLoading(false);
						setTimeout(() => {
							window.scrollTo({
								behavior: "smooth",
								top: divRef.current.offsetTop
							})
						}, 200)
						setAddStatus(2)
					} else {
						setLoading(true);
					}
				})
				.catch(setLoading(true));
		}
	}

	const removeCart = () => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
		const getuser = JSON.parse(localStorage.getItem("auth"));
		HTTP.post("/deleteCart", {ip, device, utcLog}, { headers: { Authorization: `bearer ${getuser.token}` } })
			.then((res) => {
				if (res.data) {
					// setTicket(res.data.cart?.tickets)
					getTicket()
					setCounter("")
					setLoading(false);
					setTimeout(() => {
						window.scrollTo({
							behavior: "smooth",
							top: divRef.current.offsetTop
						})
					}, 200)
				} else {
					setLoading(true);
				}
			})
			.catch(setLoading(true));
	}

	const getSeat = (categoryId) => {
		const myArray = categoryId.split(",");
		const mySeat = myArray[0];
		const myGroup = myArray[1];
		const myPrice = myArray[2];
		const myStatus = myArray[3];
		const getuser = JSON.parse(localStorage.getItem("auth"));
		setSeatCategory(categoryId);
		setSeatId(mySeat);
		setGroupId(myGroup);
		setPrice(myPrice)
		setPriceStatus(myStatus)
		setLoading(true);
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)

		if (categoryId !== "0") {
			HTTP.get(`/activities/getUnsoldSeats/${myGroup}`, { headers: { Authorization: `bearer ${getuser.token}` } })
				.then((res) => {
					if (res.data.result) {
						console.log(res.data.seats)
						setSeat(res.data.seats);
						setLoading(false);
						setTimeout(() => {
							window.scrollTo({
								behavior: "smooth",
								top: divRef.current.offsetTop
							})
						}, 200)
					}
				})
				.catch(setLoading(true));
		} else {
			setLoading(false);
		}
	};



	const sendExtraMail = () => {
		const getuser = JSON.parse(localStorage.getItem("auth"));
		setLoading(true)
		formData.append("email_extra", extraMail);
		formData.append("type", 1);
		formData.append("lang", 'tr');
		HTTP.post(`/ticketMail`, (formData), { headers: { Authorization: `bearer ${getuser.token}` } })
			.then((res) => {
				if (res.data.result) {
					setActivatedModal(true)
					setLoading(false)
					toast(res.data.message, {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: false,
						pauseOnHover: false,
						draggable: false,
						progress: undefined,
						theme: "light",
						closeButton: false,
						type: "success"
					})
				} else {
					setLoading(false)
					toast(res.data.errors.email_extra[0], {
						position: "top-right",
						autoClose: 3000,
						hideProgressBar: false,
						closeOnClick: false,
						pauseOnHover: false,
						draggable: false,
						progress: undefined,
						theme: "light",
						closeButton: false,
						type: "error"
					})
				}
			})
			.catch(setLoading(true));
	}

	

	const getTicket = () => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
		const getuser = JSON.parse(localStorage.getItem("auth"));
		HTTP.get("/getCart", { headers: { Authorization: `bearer ${getuser.token}` } })
			.then((res) => {
				if (res.data.cart) {
					selectedCategory(category)
					setTicket(res.data.cart.tickets)
					localStorage.setItem('basketsNumber', JSON.stringify(res.data.cart.tickets.length))
					setCounter(res.data.cart.cartTime)
					setLoading(false);
					setTimeout(() => {
						window.scrollTo({
							behavior: "smooth",
							top: divRef.current.offsetTop
						})
					}, 200)
				} else {
					setTicket("")
					localStorage.setItem('basketsNumber', JSON.stringify(0))
					setCounter("")
					setLoading(false)
				}
			})
			.catch(setLoading(true));
	}

	const getCart = () => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
		const getuser = JSON.parse(localStorage.getItem("auth"));
		HTTP.post("/addTicketToCart", { name_on_ticket: ticketNameSurname, seat_id: mySeats, price: price, price_id: seatId, activity_id: activityId, is_insurance: insurance == 2 ? 0 : insurance, ip_address: ip, device_name: device, utc: utcLog }, { headers: { Authorization: `bearer ${getuser.token}` } })
			.then((res) => {
				if (res.data.result) {
					// setCategory("null")
					setSeatCategory("null")
					setMySeats([])
					setSeatName("")
					setTerm(false)
					setInsurance(0)
					getTicket()
					setCounter(res.data.cart.cartTime)
					setTicketNameSurname(getuser.user.username)
					setLoading(false);
					setTimeout(() => {
						window.scrollTo({
							behavior: "smooth",
							top: divRef.current.offsetTop
						})
					}, 200)
					setAddStatus(1)
				} else {
					setLoading(true);
				}
			})
			.catch(setLoading(true));
	};

	const formattedUtc = (hour) => {
		if (hour == 0) {
			var result = 'UTC';
			return result;
		} else {
			if (hour < 0) {
				var signal = 'UTC - ';
				var hour = hour * -1;
			} else {
				var signal = 'UTC + ';
			}

			if (hour < 10) {
				var formatHour = '0' + parseInt(hour);
			} else {
				var formatHour = parseInt(hour);
			}

			if (hour % 1 > 0) {
				var formatMinute = (hour % 1 * 60);
			} else {
				var formatMinute = '00';
			}

			result = signal + formatHour + ':' + formatMinute;
			return result;
		}
	}

	const reportControl = () => {
		if (password == 4316) {
			setReportModal(false)
			window.location.href = globals.url + "a/activityreport/" + number
		}
	}

	function NumToTime(num) {
		var hours = Math.floor(num / 60);
		var minutes = num % 60;
		if (minutes + ''.length < 2) {
			minutes = '0' + minutes;
		}
		return hours + ":" + minutes;
	}

	const ticketIncrease = () => {
		if (ticketNumber < 20 && ticketNumber < seat.length) {
			const increase = ticketNumber + 1
			setTicketNumber(increase)
		}
	}

	const ticketDecrease = () => {
		if (ticketNumber > 1) {
			const decrease = ticketNumber - 1
			setTicketNumber(decrease)
		}
	}

	const linkProps = {
		onClick: (event) => {
			event.preventDefault()
			//  window.open(extra)
			setCopy(false)
			setUrlModal(true)
		}
	};

	const urlCopy = () => {
		setCopy(true)
		toast(_('copied'), {
			position: "top-right",
			autoClose: 3000,
			type: 'success',
			hideProgressBar: false,
			closeOnClick: false,
			pauseOnHover: false,
			draggable: false,
			progress: undefined,
			theme: "light",
			closeButton: false
		})
		setUrlModal(false)
	}

	const addTicketNumber = () => {
		setTimeout(() => {
			window.scrollTo({
				behavior: "smooth",
				top: divRef.current.offsetTop
			})
		}, 200)
		const getuser = JSON.parse(localStorage.getItem("auth"));
		for (let i = 0; i < ticketNumber; i++) {
			mySeats.push(seat[i].id)
		}
		setMySeats(mySeats)
		if (mySeats.length > 0) {
			HTTP.post("/addTicketToCart", { name_on_ticket: ticketNameSurname, seat_id: mySeats, price: price, price_id: seatId, activity_id: activityId, is_insurance: insurance == 2 ? 0 : insurance, ip_address: ip, device_name: device, utc: utcLog }, { headers: { Authorization: `bearer ${getuser.token}` } })
				.then((res) => {
					if (res.data.result) {
						setSeatCategory("null")
						selectedCategory(category)
						setMySeats([])
						setSeatName("")
						setTerm(false)
						setInsurance(0)
						getTicket()
						setCounter(res.data.cart.cartTime)
						setTicketNameSurname(getuser.user.username)
						setLoading(false);
						setPriceStatus("")
						setTimeout(() => {
							window.scrollTo({
								behavior: "smooth",
								top: divRef.current.offsetTop
							})
						}, 200)
						setAddStatus(1)
					} else {
						setLoading(true);
					}
				})
				.catch(setLoading(true));
		}
	};

	useEffect(() => {
		setTimeout(() => {
			if (hall?.groups?.length < 2) {
				setCategory(0)
			}
			if (hall?.groups[0]?.prices?.length == 1) {
				const id = hall?.groups[0]?.prices[0]?.id
				const group_id = hall?.groups[0]?.prices[0]?.group_id
				const price = hall?.groups[0]?.prices[0]?.price
				const status = hall?.groups[0]?.prices[0]?.status
				getSeat(id + "," + group_id + "," + price + "," + status)
			}

		}, 200)
	}, [hall], divRef.current)

	const selectedCategory = (category) => {
		if (hall?.groups[category]?.prices?.length == 1) {
			const id = hall?.groups[category]?.prices[0]?.id
			const group_id = hall?.groups[category]?.prices[0]?.group_id
			const price = hall?.groups[category]?.prices[0]?.price
			const status = hall?.groups[category]?.prices[0]?.status
			getSeat(id + "," + group_id + "," + price + "," + status)
		}
	};

	const onSelect = (selectedList, selectedItem) => {
		setMySeats([...mySeats, selectedItem.id])
	}

	const onRemove = (selectedList, removedItem) => {
		const myRemove = mySeats.filter(function (item) {
			return item !== removedItem.id
		})
		setMySeats(myRemove)
	}

	const tagManagerArgs = {
		gtmId: 'GTM-PQCRDTTD',
	}

	useEffect(() => {
		TagManager.initialize(tagManagerArgs)
		window.dataLayer = [];
		if (activityName) {
			window.dataLayer.push({
				page: {
					url: window.location.href,
					title: activityName
				},
				event: 'ticketing_page'
			})
		}
	}, [activityName])

	const gtmTag = (val) =>{
        TagManager.initialize(tagManagerArgs)
        window.dataLayer.push({
            event: val
        })
    }

	useEffect(() => {
		if (addStatus == 1) {
			window.dataLayer.push({
				ticket: {
					ticket: ticket,
					ticket_number: ticket.length
				},
				event: 'add_to_cart'
			})
		}
	}, [ticket])

	useEffect(() => {
		if (addStatus == 2) {
			window.dataLayer.push({
				ticket: {
					ticket: ticket,
					ticket_number: ticket.length
				},
				event: 'delete_to_cart'
			})
		}
	}, [ticket])

	useEffect(() => {
		if (ticket.length > 0 && counter < 0) {
			removeCart();
		}
	}, [ticket,counter]);

	return (
		<div>
			<Helmet>
				<title>Vidipass | {activityName}</title>
				<meta name="description" content={metaDescription?metaDescription:description}/>
                <meta name="keywords" content={metaKeywords.filter(Boolean).join(", ")}/>
				<meta name="robots" content="index, follow"/>
				<link rel="alternate" href={window.location.href} hreflang="tr"/>
				<link rel="canonical" href={window.location.href} hreflang="tr"/>
			</Helmet>
			{loading && <Loader/>}
				<>
					<Navbar />
					<div className="container mb-5 px-4 mt-5">
						<div className="row flex-column flex-lg-row ">
						<div className="col-12 card-back p-1 rounded-2">
  <div className="card p-2">
    <div className="row g-1 align-items-center justify-content-center">
      <div className="col-4 d-flex justify-content-center align-items-center">
        {activityPhoto && (
          <img
            src={`${globals.imgUrl}${activityId}/thumb/${activityPhoto}.png?${new Date(imgCreated).getTime()}`}
            className="rounded-2 img-fluid activity-photo"
            alt="activity photo"
          />
        )}
      </div>

      <div className="col-8 d-flex flex-column align-items-center">
        <div className="text-center">
          <h3 className="mb-1" style={{ color: "#2E81D2", fontSize: 16 }}>
            {activityName}
          </h3>
          <p className="text-muted mb-1" style={{ fontSize: 12 }}>
            {_(activityCategoryName)}
          </p>
        </div>

        <div className="d-flex justify-content-center w-100">
          <div className="text-center me-3">
            <h4 className="mb-0" style={{ fontSize: 11, fontWeight: 500 }}>{_('start_time')}</h4>
            <span className="text-muted" style={{ fontSize: 11 }}>
              {startDate} - {startTime}
            </span>
			<div className="text-muted" style={{ fontSize: 11 }}>
				{_(startDateDay)}
            </div>
          </div>
          <div className="text-center">
            <h4 className="mb-0" style={{ fontSize: 11, fontWeight: 500 }}>{_('end_time')}</h4>
            <span className="text-muted" style={{ fontSize: 11 }}>
              {endDate} - {endTime}
            </span>
			<div className="text-muted" style={{ fontSize: 11 }}>
				{_(endDateDay)}
            </div>
          </div>
        </div>

        <div className="text-center mt-1">
          <h4 className="mb-0" style={{ fontSize: 11, fontWeight: 500 }}>{_('place')}</h4>
          <span className="text-muted" style={{ fontSize: 11 }}>
            {cityName}, {place}
          </span>
        </div>
      </div>
    </div>

    <div className="text-center mt-1">
      <small className="text-muted" style={{ fontSize: 10 }}>
        <span className="bi bi-info-circle" style={{ color: "#933CAC" }} />{" "}
        {_("activity_detail_info").replace("%s", `https://vidipass.com/a/${number}`)}
      </small>
    </div>
  </div>
</div>


							<Modal show={showSaloonModal} onHide={() => setShowSaloonModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("seating_arrangement")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<div className="col-12 d-flex justify-content-center align-items-center">
										<Zoom>
											<div className="col-12 d-flex justify-content-center">
												<img src={globals.imgUrl + activityId + "/hall/" + "hall" + ".png" + '?' + new Date(imgCreated).getTime()} className="w-50" />
											</div>
										</Zoom>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={reportModal} onHide={() => setReportModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("activity_report")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<div className="col-12 d-flex justify-content-center align-items-center">
										<div className="form-group">
											<label className="form-control-label text-muted">{_("password")}</label>
											<input type="text" value={password} onChange={(e) => setPassword(e.target.value)} placeholder={_("password")} className="form-control" />
										</div>
									</div>
									<div className="text-center mt-3" onClick={() => reportControl()}>
										<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
											{_("ok")}
										</a>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={deleteCartModal} onHide={() => setDeleteCartModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("empty_cart")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<div className="col-12 d-flex justify-content-center align-items-center">
										<h6 className="homeText">{_("sure")}</h6>
									</div>
									<div className="col-12 d-flex justify-content-center align-items-center">
										<div className="row">
											<div className="col text-end" onClick={() => removeCart() + setDeleteCartModal(false)}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													<i className="bi bi-check-circle-fill"></i>
												</a>
											</div>
											<div className="col text-end" onClick={() => setDeleteCartModal(false)}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													<i className="bi bi-x-circle-fill"></i>
												</a>
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={deleteTicketModal} onHide={() => setDeleteTicketModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("delete_ticket")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<div className="col-12 d-flex justify-content-center align-items-center">
										<h6 className="homeText">{_("sure")}</h6>
									</div>
									<div className="col-12 d-flex justify-content-center align-items-center">
										<div className="row">
											<div className="col text-end" onClick={(e) => removeTicket(deleteTicketId) + setDeleteTicketModal(false)}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													<i className="bi bi-check-circle-fill"></i>
												</a>
											</div>
											<div className="col text-end" onClick={() => setDeleteTicketModal(false)}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													<i className="bi bi-x-circle-fill"></i>
												</a>
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={cancelInsuranceModal} onHide={() => setCancelInsuranceModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("cancel_insurance")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<div className="col-12 d-flex justify-content-center align-items-center">
										<p style={{ fontSize: 14 }} className="text_muted">{_("cancel_insurance_text")}</p>
									</div>
									<div className="col-12 d-flex justify-content-center align-items-center">
										<p className="homeText">{_("cancel_insurance_sure")}</p>
									</div>
									<div className="col-12 d-flex justify-content-center align-items-center">
										<div className="row">
											<div className="col text-end" onClick={(e) => setTerm(!term) + setInsurance(0) + setCancelInsuranceModal(false)}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													<i className="bi bi-check-circle-fill"></i>
												</a>
											</div>
											<div className="col text-end" onClick={() => setTerm(term) + setInsurance(1) + setCancelInsuranceModal(false)}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													<i className="bi bi-x-circle-fill"></i>
												</a>
											</div>
										</div>
									</div>
								</Modal.Body>
							</Modal>
							<Modal show={activatedModal} onHide={() => setActivatedModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("verify_code")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<form
										onSubmit={(e) => {
											e.preventDefault();
											if (codeStart.length == 2 && codeEnd.length == 2) {
												const getuser = JSON.parse(localStorage.getItem("auth"));
												setLoading(true)
												setCodeStart('')
												setCodeEnd('')
												formData.append("code", codeStart + codeEnd);
												formData.append("lang", 'tr');
												HTTP.post(`/emailActivation`, (formData), { headers: { Authorization: `bearer ${getuser.token}` } })
													.then((res) => {
														setLoading(false)
														if (res.data.result) {
															setActivatedModal(false)
															localStorage.setItem('auth', JSON.stringify(res.data))
															toast(res.data.message, {
																position: "top-right",
																autoClose: 3000,
																hideProgressBar: false,
																closeOnClick: false,
																pauseOnHover: false,
																draggable: false,
																progress: undefined,
																theme: "light",
																closeButton: false,
																type: "success"
															})
															setTimeout(() => {
																window.location.reload()
															}, 3000);
														} else {
															toast(res.data.message, {
																position: "top-right",
																autoClose: 3000,
																hideProgressBar: false,
																closeOnClick: false,
																pauseOnHover: false,
																draggable: false,
																progress: undefined,
																theme: "light",
																closeButton: false,
																type: "error"
															})
														}
													})
													.catch(setLoading(true));
											} else {
												toast(_('password_must_be_four_characters'), {
													position: "top-right",
													autoClose: 3000,
													hideProgressBar: false,
													closeOnClick: false,
													pauseOnHover: false,
													draggable: false,
													progress: undefined,
													theme: "light",
													closeButton: false,
													type: "error"
												})
											}
										}}
									>
										<div className="row justify-content-center mb-3 mt-3">
											<p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("enter_verify_code_for_email")}</p>
											<p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >{_("control_spam_box")}</p>
											<p className="d-flex justify-content-center" style={{ color: "#933CAC" }} >({extraMail})</p>
										</div>
										<div className="form-group">
											<label className="form-control-label text-muted">{_("verify_code")}</label>
											<div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white">
												<span type="text" className="me-1" autofocus="">v</span>
												<span type="text" className="me-1" autofocus="">-</span>
												<input value={codeStart} onChange={(e) => handleCodeStart(e.target.value)} style={{ width: 50 }} type="text" className="form-control me-1" />
												<span type="text" className="me-1" autofocus="">-</span>
												<input value={codeEnd} onChange={(e) => handleCodeEnd(e.target.value)} style={{ width: 50 }} type="text" className="form-control" />
											</div>
										</div>

										<div className="row justify-content-center my-3 px-3">
											<button disabled={!(codeStart.length == 2 && codeEnd.length == 2)} type="submit" className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >
												{_("send")}
											</button>
										</div>
									</form>
								</Modal.Body>
							</Modal>
							<Modal show={urlModal} onHide={() => setUrlModal(false)}>
								<Modal.Header closeButton style={{ background: "#fff" }}>
									<Modal.Title
										style={{ fontSize: 16 }}
									><span className="homeText">{_("online_adress")}</span></Modal.Title>
								</Modal.Header>
								<Modal.Body >
									<div className="d-flex justify-content-center align-items-center px-2">
										<CopyToClipboard text={extra}
											onCopy={() => setCopy(true) + urlCopy()}
										>
											<div className="border rounded-2 p-2 d-flex me-2 d-flex justify-content-center"  >
												<svg
													xmlns="http://www.w3.org/2000/svg"
													width="20"
													height="20"
													fill="#933CAC"
													className="bi bi-copy"
													viewBox="0 0 16 16">
													<path fill-rule="evenodd" d="M4 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V2Zm2-1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1V2a1 1 0 0 0-1-1H6ZM2 5a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h8a1 1 0 0 0 1-1v-1h1v1a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h1v1H2Z" />
												</svg>
											</div>
										</CopyToClipboard>
										<a onClick={() => window.open(extra) + setUrlModal(false)} className="homeText d-flex text-decoration-none border rounded-2 p-2">{extra}</a>
									</div>
								</Modal.Body>
							</Modal>
							{serverTime > 0 && activityStatus == 0 && ticket.length < 20 &&
								<div className="col-sm-12 col-lg-6 card p-3 mt-2">
									{
										planURL ?
											<div className="text-end mb-5" onClick={() => setShowSaloonModal(true) + gtmTag('seating_arrangement')}>
												<a className="text-decoration-none btn btn-primary saloonButton rounded-2">
													{_("seating_arrangement")}
												</a>
											</div>
											:
											<div className="card card-back-info mb-3">
												<div className="row card m-1">
													<p className="text-muted d-flex mt-auto mb-auto">{_('no_seating_arrangement')}</p>
												</div>
											</div>
									}
									<span className="homeText text-start"><i className="bi bi-geo-alt-fill"></i> {hall.name}</span>
									<span className="homeText">{_('category')}</span>
									<div className="col-sm-12 mb-3">
										{hall?.groups?.map((item, i) => {
											return (
												<div key={i} className="form-check">
													<input
														type="radio"
														className="form-check-input custom-radio"
														id={`category-${i}`}
														name="category"
														value={i}
														onChange={(e) => {
															setCategory(e.target.value);
															setMySeats([]);
															setSeatName("");
															setSeatCategory("null");
															selectedCategory(e.target.value);
															setTimeout(() => {
																window.scrollTo({
																	behavior: "smooth",
																	top: divRef.current.offsetTop,
																});
															}, 200);
														}}
														checked={category == i}
													/>
													<label className="form-check-label" htmlFor={`category-${i}`}>
														{item.name}
													</label>
												</div>
											);
										})}
									</div>
									{category != "null" && (
										<>
											<span className="homeText">{_('ticket_type')}</span>
											<div className="col-sm-12 mb-3">
												{hall?.groups[category]?.prices?.map((item, i) => {
													const value = `${item.id},${item.group_id},${item.price},${item.status}`;
													return (
														<div key={i} className="form-check">
															<input
																type="radio"
																className="form-check-input custom-radio"
																id={`price-${i}`}
																name="seatCategory"
																value={value}
																onChange={(e) => getSeat(e.target.value)}
																checked={seatCategory === value}
															/>
															<label className="form-check-label" htmlFor={`price-${i}`}>
																{item.name} - {item.price} {priceCode}
															</label>
														</div>
													);
												})}
											</div>
										</>
									)}
									{seat && seatCategory !== "null" && category !== "null" && (
										<>
											{
												priceStatus == 1 ?
													<>{
														seat.length>0 ? 
														<>
															<span className="homeText"> {_('number_of_tickect')}</span>
														<div className="d-flex justify-content-center align-items-center flex-row form-control headerInput bg-white mb-3">
															{
																ticketNumber > 1 ?
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
																	:
																	<span onClick={() => ticketDecrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-dash-circle rounded p-2"></span>
															}
															<input value={ticketNumber} disabled style={{ width: 50, backgroundColor: '#FFFFFF' }} type="text" className="form-control me-1 ms-1 text-center" />
															{
																(ticketNumber < 20 && ticketNumber < seat.length) ?
																	<span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#933CAC', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
																	:
																	<span onClick={() => ticketIncrease()} style={{ backgroundColor: '#FFFFFF', color: '#CDCDCD', justifyContent: 'center', alignItems: 'center', display: 'flex', fontSize: 20 }} className=" bi bi-plus-circle rounded p-2"></span>
															}
														</div>
														</>:
														<span>{_('no_seats_search_criteria')}</span>
													}
													
													</>
													:
													<>
														<span className="homeText"> {_('armchair')}</span>
														<div className="col-sm-12 mb-3">
															<Multiselect
																className="bg-white"
																options={seat}
																displayValue="name"
																selectionLimit={20}
																avoidHighlightFirstOption
																emptyRecordMsg={_('no_seats_search_criteria')}
																placeholder={_('please_select_seat')}
																selectedValues={seat.selectedValue}
																onSelect={onSelect}
																onRemove={onRemove}
																style={{
																	searchBox: { // To change search box element look
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	inputField: { // To change input field position or margin
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	},
																	chips: { // To change css chips(Selected options)
																		backgroundColor: '#933CAC'
																	},
																	optionContainer: { // To change css for option container 
																		maxHeight: '150px', // Açılan kutunun maksimum yüksekliği
																		overflowY: 'auto', // Kaydırma çubuğu ekler
																		borderColor: '#933CAC',
																		borderRadius: '5px'
																	}
																}}
															/>
														</div>
													</>
											}
										</>
									)}

									{((seat && seatCategory !== "null" && category !== "null" && (mySeats.length !== 0 || ticketNumber>0))) && (
										<>
											<span className="homeText"> {_('name_on_ticket')}</span>
											<span style={{ fontSize: 14 }} className="text-muted">{_("add_name_to_ticket")} <span style={{fontWeight:'bold'}} className="homeText">|</span> 0 TRY</span>
											<span style={{ fontSize: 14 }} className="text-muted">{_('name_on_ticket_info')}</span>
											<div className="col-sm-12">
												<div className="form-group">
													<input required type="text" value={ticketNameSurname} onChange={(e) => setTicketNameSurname(e.target.value)} placeholder={_("name_on_ticket")} className="form-control" />
												</div>
											</div>
											{
												moment(startDateTime).diff(moment(), 'seconds') > 86400 &&
												<>
													<span className="homeText mt-3"> {_('ticket_insurance')}</span>
													<div className="form-check">
														<label className="form-check-label" htmlFor="exampleCheck1">
															<input type="checkbox" className="form-check-input text-muted" id="exampleCheck1" checked={term} onChange={onChangeTerm} />
															<div className="row">
																<span style={{ fontSize: 14 }} className="text-muted">{_("to_ticket_insurance")} <span style={{fontWeight:'bold'}} className="homeText">|</span> 1 TRY</span>
																<span style={{ fontSize: 14 }} className="text-muted"> {_('insurance_info')}</span>	
															</div>
														</label>
													</div>
												</>
											}
										</>
									)}

									{((seat && (mySeats.length !== 0 || ticketNumber>0) &&
										seatCategory !== "null" &&
										category !== "null" &&
										mySeats)) && (
											activatedMail ?
												<>
													<div className="mt-3">
														<span className="homeText"> {_('e_mail')}</span>
														<div className="row">
															<span style={{ fontSize: 14 }} className="text-muted">{_("want_to_email")} <span style={{fontWeight:'bold'}} className="homeText">|</span> 0 TRY</span>
															<span style={{ fontSize: 14 }} className="text-muted">{_("ticket_email")}</span>
														</div>
														<div className="col-sm-12">
															<div className="input-group">
																<input disabled={true} required type="text" value={activatedMail} placeholder={_("e_mail")} className="form-control text-muted bg-white" />
															</div>
														</div>
													</div>
													<div className="row justify-content-center my-3 px-3">
														<a onClick={priceStatus != 1 ? getCart : addTicketNumber} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >
															{_("add_to_cart")}
														</a>
													</div>
												</>
												:
												<>
													{
															<div className="row justify-content-center my-3 px-3">
																<a onClick={priceStatus != 1 ? getCart : addTicketNumber} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" >
																	{_("add_to_cart")}
																</a>
															</div>
													}
												</>
										)
									}
								</div>
							}

							{ticket.length > 0 &&
								<>
									<div className="col-sm-12 col-lg-6 card p-3 mt-2">
										<div className="row">
											<h6 className="homeText col d-flex justify-content-start">{_('number_of_tickets')}: {ticket.length}</h6>
											{counter > 0 &&
												(<h6 className="homeText col d-flex justify-content-end">{_('basket_duration')}: {NumToTime(counter)}</h6>)
												
											}
										</div>

										{ticket.map((val, i) => {
											return (
												<div className="border rounded-2 mb-1 m-1">
													<div className="text-end">
													<button 
														onClick={e => { 
															frontRemoveTicket(val.id); 
															gtmTag('delete_ticket');
														}} 
														className="btn btn-sm d-flex align-items-center ms-auto"
														style={{backgroundColor: '#933CAC', color: '#FFF'}}
													>
														<i className="bi bi-trash-fill me-1"></i>
														{_('delete')}
													</button>
													</div>
													<div className="row d-flex justify-content-center">
													<h6 className="my-0 text-muted text-center text-truncate">{val?.activity?.title}</h6>
													{hall?.groups?.map((item, i) => {
																		return item.id == val.current_price.group_id &&
																			<small className="text-muted text-truncate text-center">{_('category')}: {item.name}</small>
																	})}

													</div>
													
													<div className="col-12">
														<div className="row">
															<div className="col-6 d-flex text-center align-items-center">
																<div className="col text-truncate">
																	
																	<div className="row">
																		<small className="text-muted text-truncate">{moment(val?.activity?.start_time).format("DD.MM.YYYY")}</small>
																		<small className="text-muted text-truncate">{_(moment(val?.activity?.start_time).format("dddd").toLocaleLowerCase())}</small>
																		<small className="text-muted text-truncate">{moment(val?.activity?.start_time).format("HH:mm")}</small>
																	</div>
																</div>
															</div>
															<div className="col-6 d-flex text-center justify-content-center align-items-center text-truncate">
																<div className="row text-truncate text-center">
																	
																	<small className="text-muted text-truncate">{_('type')}: {val.current_price.name}</small>
																	{
																		val.is_insurance ?
																			<small className="text-muted text-truncate">{_('fee')}: {val.current_price.price + val.insurance_fee} {priceCode}</small>
																			:
																			<small className="text-muted text-truncate">{_('fee')}: {val.current_price.price} {priceCode}</small>
																	}
																	{
																		val.current_price.status == 1 ?
																			<small className="text-muted text-truncate">{_('ticket')}: {_('numberless')}</small>
																			:
																			<small className="text-muted text-truncate">{_('armchair')}: {val.seat.name}</small>
																	}

																</div>
															</div>
															
														</div>
													</div>
												</div>
											)
										})
										}
										<div className="row justify-content-center my-3 px-3">
											<a onClick={() => window.dataLayer = [] + window.dataLayer.push({
												ticket: {
													ticket: ticket,
													ticket_number: ticket.length
												},
												event: 'cart_details'
											})} className="text-decoration-none btn btn-primary cardButton rounded-2 col-6" href={globals.url + "i/payment/"}>{_("go_to_cart")}</a>
										</div>
									</div>
								</>
							}
						</div>
					</div>
					<div onClick={() => gtmTag('whatsapp_icon')}>
					<WhatsAppWidget
						phoneNo="905010700526"
						position="right"
						widgetWidth="300px"
						widgetWidthMobile="260px"
						// autoOpen={true}
						// autoOpenTimer={2000}
						messageBox={true}
						messageBoxTxt=""
						iconSize="40"
						iconColor="white"
						iconBgColor="green"
						headerIcon={images.vidipass_logo}
						headerIconColor="black"
						headerTxtColor="white"
						headerBgColor="#933CAC"
						headerTitle="Vidipass"
						headerCaption={_("online")}
						bodyBgColor="#bbb"
						chatPersonName={_("vidipass_assistant")}
						chatMessage={<>{_("hi")} 👋 <br />{_("how_can_help")}</>}
						footerBgColor="#999"
						placeholder={_("write_to_assistant")}
						btnBgColor="#933CAC"
						btnTxt={_("send")}
						btnTxtColor="white"
					/>
					</div>
					<span ref={divRef} />
					<Footer />
				</>
		</div>
	);
};

export default Ticketing;